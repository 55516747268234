import 'tw-elements';
import { useEffect, useState  } from 'react';
import '../Css/TailsPrueba.css'

import {  Link, useNavigate } from "react-router-dom";
//NEW BACKEND
import { login, clearErrors } from '../../actions/userActions';


//OLD BACKEND


import Swal from 'sweetalert2';

import { useDispatch, useSelector } from 'react-redux';
import MetaData from '../layout/MetaData';







const FormLoginTail = () => {

//const [cookies] = useCookies([])
const navigate = useNavigate();
const dispatch = useDispatch();
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');

const auth = useSelector(state => state.auth);
const { isAuthenticated, error } = auth;


useEffect(() => {
  

  if(isAuthenticated) {
     
    navigate("/home");
    
  }

  if (error){
   // alert.error(error);
    Swal.fire('Authentication failed', error, 'error');
    dispatch(clearErrors());
  }

}, [ dispatch, alert, isAuthenticated, error])

const submitHandler = (e) => {
  e.preventDefault();
  dispatch(login(email, password))
}





  return  (<>

  <MetaData title={'Login User GTM'} />
  <div className="h-screen flex flex-col justify-center items-center bg-gray-100">
      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-80"
        onSubmit={ submitHandler }
      >
        <h2 className="text-3xl font-bold mb-4 text-gray-800">Log in</h2>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
          Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="password">
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Log in
          </button>
          <Link
            className=" inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
            to="/register"
          >
           You do not have an account?
          </Link>
          
         
        </div>
        <div className="flex items-center justify-end">
        <Link
            className=" hover:text-red-800 inline-block align-baseline font-bold text-sm text-red-500 "
            to="/login-collaborator"
          >
           Collaborator account?
          </Link>
        </div>
      </form>
    </div>




</>
)
 }

 export default FormLoginTail