import React, { useState, useEffect } from 'react';

const PrivacyNotice = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('userConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('userConsent', 'true');
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className="fixed bottom-0 left-0 right-0 p-4 bg-gray-900 text-white flex justify-between items-center">
          <p>This site uses cookies and records calls to improve user experience. Do you accept?</p>
          <button onClick={handleAccept} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Aceptar
          </button>
        </div>
      )}
    </>
  );
};

export default PrivacyNotice;
