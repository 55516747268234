import React from "react";
import CookieConsent from "react-cookie-consent";
import "tailwindcss/tailwind.css";



const CookieBanner = () => {
    return (
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        enableDeclineButton
        declineButtonText="Decline"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#0D0E13" }}
        buttonStyle={{
          background: "#F4CA16",
          color: "#0D0E13",
          fontWeight: "bold",
          borderRadius: "4px",
          padding: "10px 20px",
          cursor: "pointer",
        }}
        declineButtonStyle={{
          background: "#F44336",
          color: "#FFFFFF",
          fontWeight: "bold",
          borderRadius: "4px",
          padding: "10px 20px",
          cursor: "pointer",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    );
  };
  
  export default CookieBanner;
  