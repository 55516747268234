/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { Link } from "react-router-dom"


export default function SectionImgText(props) {
    return (
      <div className=" bottom-0 relative bg-white overflow-hidden">
        <div className=" pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
            <div className="sm:max-w-lg">
              <h1 className="text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-6xl">
                {props.title}
              </h1>
              <p className="mt-4 text-xl font-sans text-gray-700 pb-4">
                {props.text}.
              </p>
            </div>
            <div>
              <div className="">
              
                <div
                  aria-hidden="true"
                  className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
                >
                  <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-44 h-64 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                          <img
                            src={props.img1}
                            alt={props.alt1}
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src={props.img2}
                            alt={props.alt2}
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src={props.img3}
                            alt={props.alt3}
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src={props.img4}
                            alt={props.alt4}
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src={props.img5}
                            alt={props.alt5}
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src={props.img6}
                            alt={props.alt6}
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <img
                            src={props.img7}
                            alt={props.alt7}
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
               
                <a
                
                  href="/collaborators"
                  className="inline-block text-center bg-custom-red border border-transparent rounded-md   "
                >
                  <Link 
                  className="fw-full flex items-center justify-center px-8 py-3 border border-transparent font-sans rounded-md text-white bg-custom-red hover:bg-red-700  md:py-4 md:text-lg md:px-10"
                  to={'/collaborators'} >{props.linkB} </Link> 
                  
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  