import {React,  useEffect, useState } from "react";
import { useSelector } from "react-redux";

function AddressInfo({addressInfo, onCollaboratorChange }) {
 
  const [address, setAddress] = useState(addressInfo.address);
  const [cellNo, setCellNo] = useState(addressInfo.cellNo);
  const [city, setCity] = useState(addressInfo.city);
  const [country, setCountry] = useState(addressInfo.country);
  const [officeNo, setOfficeNo] = useState(addressInfo.officeNo);
  const [phoneNo, setPhoneNo] = useState(addressInfo.phoneNo);
  const [postalCode, setPostalCode] = useState(addressInfo.postalCode);
  
  const handleCollaboratorChange = () => {
    const updatedCollaborator = {
      address,
      cellNo,
      city,
      country,
      officeNo,
      phoneNo,
      postalCode,
      
    };
    onCollaboratorChange(updatedCollaborator);
  };
 

  return (
    <div className=" text-black font-bold">
       <div className="form-group">
      <label>Address:</label>
      <input
        type="text"
        name="address"
        value={address}
        placeholder={addressInfo.address}
        onChange={(e) => {
          setAddress(e.target.value);
          handleCollaboratorChange();
        }}
      />
      </div>

      <div className="form-group">
      <label>Cell No:</label>
       <input
        type="text"
        name="cellNo"
        value={cellNo}
        placeholder={addressInfo.cellNo}
        onChange={(e) => {
          setCellNo(e.target.value);
          handleCollaboratorChange();
        }}
      />
      </div>

      <div className="form-group">
      <label>City: </label>
      <input
        type="text"
        name="city"
        value={city}
        placeholder={addressInfo.city}
        onChange={(e) => {
          setCity(e.target.value);
          handleCollaboratorChange();
        }}
      />
      </div>

      <div className="form-group">
      <label>Phone No:</label>
      <input
        type="text"
        name="phoneNo"
        value={addressInfo.phoneNo}
        placeholder={addressInfo.phoneNo}
        onChange={(e) => {
          setPhoneNo(e.target.value);
          handleCollaboratorChange();
        }}
      />
      </div>

      <div className="form-group">
      <label>Office No:</label>
      <input
        type="text"
        name="officeNo"
        value={addressInfo.officeNo}
        placeholder={addressInfo.officeNo}
        onChange={(e) => {
          setOfficeNo(e.target.value);
          handleCollaboratorChange();
        }}
      />
      </div>

      <div className="form-group">
      <label>Country:</label>
      <input
        type="text"
        name="country"
        value={addressInfo.country}
        placeholder={addressInfo.country}
        onChange={(e) => {
          setCountry(e.target.value);
          handleCollaboratorChange();
        }}
      />
      </div>

      <div className="form-group">
      <label>Postal Code:</label>
      <input
        type="text"
        name="postalCode"
        value={addressInfo.postalCode}
        placeholder={addressInfo.postalCode}
        onChange={(e) => {
          setPostalCode(e.target.value);
          handleCollaboratorChange();
        }}
      />
      </div>
    </div>
  );
}

 export default AddressInfo
