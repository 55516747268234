import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MetaData from '../../Components/layout/MetaData'
import Loading from '../../utils/Loading'
import produce from 'immer';
import { toast } from 'react-toastify'
import axios from 'axios'
import PerformanceItem from '../Components/PerformancerCollaborators'
import AddressInfo from '../Components/AddressInfoCollaborators'


const UpdateProfileCollaborator = () => {

  const { collaborator, isAuthenticatedCollaborator, loadingC } = useSelector(state => state.collaboratorAuth)

  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  
  const [description, setDescription] = useState('');
  const [imagesGallery, setImagesGallery] = useState([]);
  const [asistance, setAsistance] = useState("");
  const [services, setServices] = useState("");
  const [slogan, setSlogan] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [owner, setOwner] = useState("");
 

  const [hoursMToSInit, setHoursMToSInit] = useState('');
  const [hoursMToSExit, setHoursMToSExit] = useState('');

  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [officeNo, setOfficeNo] = useState('');
  const [cellNo, setCellNo] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');

  const [paymentMethod, setpaymentMethod ] = useState({
    card: Boolean,
    cash: Boolean,
    check: Boolean,
  });
  const [cities, setCities ] = useState({
    "New Jersey" : Boolean,
    "New York" : Boolean,
    "Philadelphia" : Boolean,
    "San Francisco": Boolean,
    "Oregon": Boolean
  });
  const [scheduleDays, setScheduleDays ] = useState({
    mondayToFriday: Boolean,
    mondayToSaturday: Boolean,
    saturday: Boolean,
    sunday: Boolean,
    hoursMToSInit: Number,
    hoursMToSExit: Number
  });
  const [performanceCollaborator, setPerformanceCollaborator] = useState([]);
  useEffect(() => {
    if (collaborator) {
      setPerformanceCollaborator(collaborator.performanceCollaborator);
    }
  }, [performanceCollaborator]);

   const [performanceCollaboratort, setPerformanceCollaboratort] = useState([
     {
       name: 'oEMGlass', 
       checked: collaborator && collaborator.performanceCollaborator && collaborator.performanceCollaborator.oEMGlass || false    
     },
     {
       name: 'insurance',
       checked: collaborator && collaborator.performanceCollaborator && collaborator.performanceCollaborator.insurance || false
     },
     {
       name: 'deliveryServices',
       checked: collaborator && collaborator.performanceCollaborator && collaborator.performanceCollaborator.deliveryServices || false
     },
     {
       name: 'localServices',
       checked: collaborator && collaborator.performanceCollaborator && collaborator.performanceCollaborator.localServices || false
     },{
       name: 'warranty',
       checked: false
     },
     {
       name: 'replacement',
       checked: false
     },
     {
       name: 'repair',
       checked: false
     },
     {
       name: 'calibration',
       checked: false
     },
     {
       name: 'sameDayService',
       checked: false
     },
     {
       name: 'nextDayService',
       checked: false
     },
     {
       name: 'registerAGRSS',
       checked: false
     },
     {
       name: 'freeMobileService',
       checked: false
     },
   ]);
  

  const [avatarPreview, setAvatarPreview] = useState('../../images/anonymous-avatar-icon-25.png');
  const [imagesGalleryPreview, setImagesGalleryPreview] = useState([]);
  
  
 
  
 

  function updatePerformanceCollaborator(index, updatedPerformance) {
    setPerformanceCollaborator(prevCollaborator =>
      produce(prevCollaborator, draft => {
        draft.performanceCollaborator[index] = { ...draft.performanceCollaborator[index], ...updatedPerformance };
      })
    );
  }

  function handlePerformanceCollaboratorChange(event, index) {
    const { name, checked } = event.target;
    setPerformanceCollaborator(prevPerformanceCollaborator => {
      const updatedPerformanceCollaborator = [...prevPerformanceCollaborator];
      updatedPerformanceCollaborator[index] = { name, checked };
      return updatedPerformanceCollaborator;
    });
  }


  const submitHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    
    const formData = new FormData();
     // Agregar campos de texto al formData
  formData.append('name', name || collaborator && collaborator.name);
  formData.append('email', email);
  formData.append('phoneNo', phoneNo);
  formData.append('description', description || collaborator && collaborator.description);
  formData.append('asistance', asistance);
  formData.append('services', services);
  formData.append('slogan', slogan || collaborator && collaborator.slogan);
  formData.append('owner', owner || collaborator && collaborator.owner);

  // Agregar la imagen de avatar al formData si se ha seleccionado
  if (avatar) {
    formData.append('avatar', avatar);
  }

  // Agregar las imágenes adicionales al formData si se han seleccionado
  if (imagesGallery.length > 4) {
    for (let i = 0; i < imagesGallery.length; i++) {
      formData.append('imagesGallery', imagesGallery[i]);
    }
  }

   // Validar que addressInfo sea un objeto
   formData.set('address', address || collaborator && collaborator.address);
   formData.set('city', city || collaborator && collaborator.city);
   formData.set('cellNo', cellNo || collaborator && collaborator.cellNo);
   formData.set('phoneNo', phoneNo || collaborator && collaborator.phoneNo);
   formData.set('officeNo', officeNo || collaborator && collaborator.officeNo);
   formData.set('postalCode', postalCode || collaborator && collaborator.postalCode);
   formData.set('country', country || collaborator && collaborator.country);
  

  formData.append('scheduleDays', JSON.stringify(scheduleDays));
  formData.append('hoursMToSInit', JSON.stringify(hoursMToSInit));
  formData.append('hoursMToSExit', JSON.stringify(hoursMToSExit));

  // Agregar los métodos de pago al formData
  formData.append('card', paymentMethod.card);
  formData.append('cash', paymentMethod.cash);
  formData.append('check', paymentMethod.check);

  // Agregar la información de la ciudad al formData
  formData.append('cities', JSON.stringify(cities));

  // Agregar la información de los días de horario al formData
  formData.append('mondayToFriday', scheduleDays.mondayToFriday);
  formData.append('mondayToSaturday', scheduleDays.mondayToSaturday);
  formData.append('saturday', scheduleDays.saturday);
  formData.append('sunday', scheduleDays.sunday);
  formData.append('hoursMToSInit', scheduleDays.hoursMToSInit);
  formData.append('hoursMToSExit', scheduleDays.hoursMToSExit);

  // Agregar la información de desempeño del colaborador al formData
   formData.append('Calibration', performanceCollaborator.calibration);
   formData.append('Delivery Services', performanceCollaborator.deliveryServices);
   formData.append('Free Mobile Service', performanceCollaborator.freeMobileService);
   formData.append('insurance', performanceCollaborator.insurance);
   formData.append('localServices', performanceCollaborator.localServices);
   formData.append('nextDayService', performanceCollaborator.nextDayService);
   formData.append('oEMGlass', performanceCollaborator.oEMGlass);
   formData.append('registerAGRSS', performanceCollaborator.registerAGRSS);
   formData.append('repair', performanceCollaborator.repair);
   formData.append('replacement', performanceCollaborator.replacement);
   formData.append('sameDayService', performanceCollaborator.sameDayService);
   formData.append('warranty', performanceCollaborator.warranty);



    try {
      const response = await axios.put('/api/v1/collaborator-user/me/update', formData, {
       
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <>
      { !isAuthenticatedCollaborator ? <Loading /> : (
        <>
        <MetaData title={collaborator.name} />
        
        
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 sliderInit">
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                <p className="mt-1 text-sm text-gray-600">
                  Personal information, these are the only ones we request for the management of our service, please do not introduce credit cards. We do not request this information on the page or via email.
                  </p>
                  <p className="mt-1 text-sm text-gray-600">
                  When uploading the images, keep in mind that we only request the necessary ones, such as the VIN or glass that you need to repair.
                  </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form action="#" method="PUT" onSubmit={submitHandler}>
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                          Name:
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="given-name"
                          placeholder={collaborator && collaborator.name}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="slogan" className="block text-sm font-medium text-gray-700">
                          Slogan:
                        </label>
                        <input
                          type="text"
                          name="slogan"
                          id="slogan"
                          placeholder={collaborator && collaborator.slogan}
                          value={slogan}
                          onChange={(e) => setSlogan(e.target.value)}
                          autoComplete="slogan"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-4">
                        <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                         Email:
                        </label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          value={email}onChange={(e) => setEmail(e.target.value)}
                          autoComplete="email"
                          placeholder= {collaborator.email}
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-4">
                        <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                        Owner:
                        </label>
                        <input
                          type="text"
                          name="owner"
                          id="owner"
                          value={owner}onChange={(e) => setOwner(e.target.value)}
                          autoComplete="name"
                          placeholder= {collaborator.owner}
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-4">
                        <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                        Description:
                        </label>
                        <input
                          type="text"
                          name="description"
                          id="description"
                          value={description}onChange={(e) => setDescription(e.target.value)}
                          autoComplete="text"
                          placeholder= {collaborator.description}
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      
        
                       <div className="col-span-6 sm:col-span-3">
                       {Object.keys(cities).map((name) => (
                          <div key={name}>
                            <label>
                              <input
                                type="checkbox"
                                checked={cities[name]}
                                onChange={(e) => setCities({ ...cities, [name]: e.target.checked })}
                              />
                              {name}
                            </label>
                          </div>
                        ))}
                      </div> 
                       
                        
                          {/*  **** ADDRESS INFO COLLABORATOR UPDATE ****  */}
                          <>
                          <div className="col-span-6">
                          <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                            Street address
                          </label>
                          <input
                            type="text"
                            name="address"
                            id="address"
                            placeholder={collaborator && collaborator.address}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            autoComplete="street-address"
                            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
        
                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                          City
                        </label>
                        <input
                          type="text"
                          name="city"
                          id="city"
                          placeholder={collaborator && collaborator.country}
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          autoComplete="address-level2"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                          State / Province
                        </label>
                        <input
                          type="text"
                          name="region"
                          id="region"
                          placeholder={collaborator && collaborator.city}
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          autoComplete="address-level1"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">
                          ZIP / Postal code
                        </label>
                        <input
                          type="text"
                          name="postalCode"
                          id="postalCode"
                          placeholder={collaborator && collaborator.postalCode}
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                          autoComplete="postalCode"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                        {/* NUMEROS DE CONTACTO */}
        
                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label htmlFor="cellNo" className="block text-sm font-medium text-gray-700">
                          You number Cellphone
                        </label>
                        <input
                          type="tel"
                          name="cellNo"
                          id="cellNo"
                          placeholder={collaborator && collaborator.cellNo}
                          value={cellNo}
                          onChange={(e) => setCellNo(e.target.value)}
                          autoComplete="tel"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="officeNo" className="block text-sm font-medium text-gray-700">
                          Contact 1
                        </label>
                        <input
                          type="tel"
                          name="officeNo"
                          id="officeNo"
                          placeholder={collaborator && collaborator.officeNo}
                          value={officeNo}
                          onChange={(e) => setOfficeNo(e.target.value)}
                          autoComplete="tel"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="phoneNo" className="block text-sm font-medium text-gray-700">
                         Contact 2
                        </label>
                        <input
                         type="tel"
                         name="phoneNo"
                         id="phoneNo"
                         placeholder={collaborator && collaborator.phoneNo}
                         value={phoneNo}
                         onChange={(e) => setPhoneNo(e.target.value)}
                         autoComplete="tel"
                         className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
               
                         
                          {/* {collaborator.addressInfo && collaborator.addressInfo.map((addressInfo, index) => (
  <div className="col-span-6 sm:col-span-3" key={index}>
    <AddressInfo
      addressInfo={addressInfo}
      onCollaboratorChange={(updatedAddressInfo) => updatedAddressInfoCollaborator(index, updatedAddressInfo)}
    />
  </div>
))} */}
                        
                          <div>
                          
                           
                            {/* ...otros componentes que quieras renderizar */}
                          </div>
                          </>


                        {/*  **** PERFORMANCE COLLABORATOR UPDATE ****  */}
          
        
                         <div className="col-span-6 sm:col-span-3">
                      
                        
                         {performanceCollaborator.map((item, index) => (
        <label key={item.name}>
          <input type="checkbox" name={item.name} checked={item.checked} onChange={event => handlePerformanceCollaboratorChange(event, index)} />
          {item.name}
        </label>
      ))}
            {/* {collaborator.performanceCollaborator && collaborator.performanceCollaborator.map((performanceCollaborator, index )=> [
            <>
              <div className="col-span-6 sm:col-span-3" key={index}>
          <PerformanceItem
            performanceCollaborator={performanceCollaborator}
            onChange={(index, updatedPerformance) => updatePerformanceCollaborator(index, updatedPerformance)}
            index={index}
            checked={performanceCollaborator[index]}
          />
        </div>
            </>
          ])} */}

        </div> 

                        {/*  **** ASSISTANCE COLLABORATOR UPDATE ****  */}
                 <div className='col-span-6  sm:col-span-3'>
                 <label htmlFor="assistance">Assistance</label>
                   <select name="assistance" onChange={(e) => setAsistance(e.target.value)} >
                     <option value=""> </option>
                     <option value="Delivery services">Delivery services</option>
                     <option value="Local services">Local services</option>
                     <option value="Both of its">Both of its</option>
                   </select>
                 </div>

      
       {/*  **** SCHELUDE COLLABORATOR UPDATE ****  */}
       <div className='col-span-6 sm:col-span-3'>
        <label htmlFor='scheduleDays'>Schedule</label>
        <select name='scheduleDays' onChange={(e) => setScheduleDays(e.target.value)}>
          <option value=''>Select Schedule</option>
          <option value='mondayToFriday'>Monday to Friday</option>
          <option value='mondayToSaturday'>Monday to Saturday</option>
          <option value='sunday'>Sunday</option>
        </select>

        {scheduleDays === 'mondayToFriday' || scheduleDays === 'mondayToSaturday' ? (
          <div>
            <label htmlFor='hoursMToSInit' className='block text-sm font-medium text-gray-700'>
              You start work at:
            </label>
            <input
                    type='time'
                    step='3600'
                    pattern='[0-9]{2}:[0-9]{2}'
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
                    value={hoursMToSInit}
                    onChange={(e) => setHoursMToSInit(e.target.value)}
                  />

            <label htmlFor='hoursMToSExit' className='block text-sm font-medium text-gray-700'>
              You end work at:
            </label>
            <input 
            type='time'
            step='3600'
            pattern='[0-9]{2}:[0-9]{2}'
            className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
            value={hoursMToSExit}
            onChange={(e) => setHoursMToSExit(e.target.value)} />
          </div>
        ) : null}
      </div>


                     
                       <div className="col-span-6">
                          <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                            Street address
                          </label>
                          {/* <input
                            type="text"
                            name="address"
                            id="address"
                            placeholder={collaborator && collaborator.addressInfo && collaborator.addressInfo.address}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            autoComplete="street-address"
                            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          /> */}
                        </div>
        
                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                          City
                        </label>
                        {/* <input
                          type="text"
                          name="city"
                          id="city"
                          placeholder={collaborator && collaborator.country}
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          autoComplete="address-level2"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        /> */}
                      </div> 
        
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                          State / Province
                        </label>
                        <input
                          type="text"
                          name="region"
                          id="region"
                          placeholder={collaborator && collaborator.addressInfo && collaborator.addressInfo.city}
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          autoComplete="address-level1"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">
                          ZIP / Postal code
                        </label>
                        {/* <input
                          type="text"
                          name="postalCode"
                          id="postalCode"
                          placeholder={collaborator && collaborator.postalCode}
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                          autoComplete="postalCode"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        /> */}
                      </div>
        
                        {/* NUMEROS DE CONTACTO */}
        
                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label htmlFor="cellNo" className="block text-sm font-medium text-gray-700">
                          You number Cellphone
                        </label>
                        {/* <input
                          type="tel"
                          name="cellNo"
                          id="cellNo"
                          placeholder={collaborator && collaborator.cellNo}
                          value={cellNo}
                          onChange={(e) => setCellNo(e.target.value)}
                          autoComplete="tel"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        /> */}
                      </div>
        
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="officeNo" className="block text-sm font-medium text-gray-700">
                          Contact 1
                        </label>
                        {/* <input
                          type="tel"
                          name="officeNo"
                          id="officeNo"
                          placeholder={collaborator && collaborator.officeNo}
                          value={officeNo}
                          onChange={(e) => setOfficeNo(e.target.value)}
                          autoComplete="tel"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        /> */}
                      </div>
        
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="phoneNo" className="block text-sm font-medium text-gray-700">
                         Contact 2
                        </label>
                        <input
                         type="tel"
                         name="phoneNo"
                         id="phoneNo"
                         placeholder={collaborator && collaborator.phoneNo}
                         value={phoneNo}
                         onChange={(e) => setPhoneNo(e.target.value)}
                         autoComplete="tel"
                         className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
        
                {/* GALERIA DE IMAGENES, VIN NUMBER, CAR IMAGES, VIN NUMBER IMAGE */}
                  <div className="hidden sm:block" aria-hidden="true">
                   <div className="py-5">
                     <div className="hidden border-t border-gray-200" />
                   </div>
                  </div>
              
         <div className="col-span-6 sm:col-span-3 lg:col-span-2">
        <label className="block  text-sm font-medium text-gray-700">
        Avatar:
        <input
                     className='m-4 font-body text-gray-900'
                     type='file'
                     name='avatar'
                     onChange={(e) => setAvatar(e.target.files[0])} 
                    // onChange={avatarChangeHandler}
                     /> <span className=' text-xs text-green-600 font-thin'> Only can upload 1 image </span>
        </label>
        {avatarPreview && (
        <div className='hidden avatar-preview'>
        <img
          className='rounded-full h-14 w-14 mt-4'
                       src={avatarPreview}
                       alt='Avatar Preview' />
        </div>
        )}
        
        <br></br><br></br>
        
        <label className="block text-sm font-medium text-gray-700">
       
        Image Gallery:
        <input
                     className='m-4 font-body text-gray-900'
                     type='file'
                    
                     name='imagesGallery'
                     onChange={(e) => setImagesGallery(e.target.files[0])} /> <span className=' text-xs text-green-600 font-thin'> You can upload 1 images</span>
        </label>
        {imagesGalleryPreview && (
        <div className='imagesGallery-preview'>
        <img
                       src={imagesGalleryPreview}
                       alt='Vin Number Car Image Preview' />
        </div>
        )}
        
        <br></br><br></br>
        
        
       
          </div>      
                  
                  
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-custom-red py-2 px-4 text-sm font-medium text-white shadow-sm hover:text-custom-red hover:bg-red-200/75 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Actualizando...' : 'Save'}
                  </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
        
        </div>
        
        
        </>
      )}



</>
);
}

export default UpdateProfileCollaborator
