import React from "react";
import '../../App.css'

import Heading from "../Utils/Heading";

import SectionTextImg from "../Utils/SectionTextImg";
import SectionArticle from "../Utils/SectionArticles";

import FeacturesSection from "../Utils/FeaturesSections";
import StatsSections from "../Utils/StatsSections";









 export function AboutUs(){


    return <>
     <main>
     <div class="relative overflow-hidden bg-no-repeat bg-cover headingA " >     
     <Heading 
        title="We talk about us"
        slogan="Come and discover our purpose."
        boton= "Get Started"/> 
     </div>
             <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 sliderInit">
             
             <SectionTextImg />
             <StatsSections />
             
             <SectionArticle />
             <FeacturesSection />
            
             
            
          
     </div>
 
   
    </main>
    
    </>
}


export default AboutUs