import 'tw-elements';
import React, { useEffect, useState } from 'react';
import '../../Components/Css/TailsPrueba.css';
import Swal from 'sweetalert2';
import { clearErrors, register } from '../../actions/collaboratorActions';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import MetaData from '../../Components/layout/MetaData';

const FormRegisterCollaborator = () => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const { name, email, password, confirmPassword } = user;
  const emailExits = useSelector((state) => {
    return Array.isArray(state.user.email) && state.user.email.includes(email)
  });
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(
    '/images/avatars/anonymous-avatar-icon-25.png'
  );
  const [hasImage, setHasImage] = useState(false);

  const alert = toast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { collaborator, isAuthenticatedCollaborator, loadingC , error } = useSelector(state => state.collaboratorAuth)

  useEffect(() => {
    if (isAuthenticatedCollaborator) {
      navigate('/home');
    }

    if (error) { 
      alert.error( error);
      dispatch(clearErrors());
    }
  }, [dispatch, isAuthenticatedCollaborator, error]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return Swal.fire('Password and Confirm Password must match');
    }
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return Swal.fire('Invalid email address');
    }

    // Check if email already exists
    if (emailExits) {
      return Swal.fire('Email already exists');
    }
    
    const formData = new FormData();
    formData.set('name', name);
    formData.set('email', email);
    formData.set('password', password);
    if (hasImage) { // si el usuario ha subido una imagen
      formData.append('avatar', avatar, avatar.name);
    }
   

    dispatch(register(formData));
  };
  const onChange = (e) => {
    if (e.target.name === 'avatar') {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(e.target.files[0]);
          setHasImage(true); // actualiza la variable hasImage
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };

  return (
  <>
          <MetaData title={'Sing In Collaborator GTM'} />
          <div className="flex items-center justify-center min-h-screen">
  <div className="mx-auto max-w-4xl lg:max-w-2xl xl:max-w-3xl">
  
    <div className="flex flex-col justify-center items-center bg-gray-100 w-full">
      
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full" onSubmit={submitHandler}>
      <h1 className="text-3xl font-bold text-left mb-6"> Collaborator signup </h1>
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-input w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={name}
              onChange={onChange}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-input w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={email}
              onChange={onChange}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 font-bold mb-2">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="form-input w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={password}
              onChange={onChange}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="confirmPassword" className="block text-gray-700 font-bold mb-2">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              className="form-input w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={confirmPassword}
              onChange={onChange}
            />
          </div> 
          <div className='form-group'>
  <label 
  htmlFor='avatar_upload'
  className='text-base ' >Avatar</label>
  <div className='d-flex align-items-center'>
      <div>
          <figure className='avatar mr-3 item-rtl'>
              <img
                  src= {avatarPreview}
                  className='h-16 w-16 object-cover rounded-full hover:scale-150 my-3'
                  alt='Avatar Preview'
              />
          </figure>
      </div>
      <div className='custom-file'>
  <input
      type='file'
      name='avatar'
      className='custom-file-input'
      id='customFile'
      accept="images/*"
      onChange={onChange}
  />
  <label className='custom-file-label' htmlFor='customFile'>
      Choose Avatar
  </label>
  <style>
    /* Hide the default button text */
   {` .custom-file-input::-webkit-file-upload-button {
      visibility: hidden;
    }
    .custom-file-input::before {
      content: 'Choose File';
      display: inline-block;
      background-color: #007bff;
      color: #fff;
      padding: 4px 10px;
      border-radius: 5px;
      cursor: pointer;
    }
    .custom-file-input:hover::before {
      background-color: #0069d9;
    }
    .custom-file-input:active::before {
      background-color: #0053ba;
    }`}
  </style>
</div>
  </div>
 </div> 



             
    
              {/* <div class="flex justify-between items-center mb-6">
                <div class="form-group form-check">
                  <input
                    type="checkbox"
                    class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    id="exampleCheck2"
                  />
                  <label class="form-check-label inline-block text-gray-800" for="exampleCheck2"
                    >Remember me 
                  </label>
                
                </div>
                {/* <a href="#!" class="text-gray-800">Forgot password?</a> 
                </div> */}
    
              <div class="text-center lg:text-left pt-4">
                <button
                  type='submit'
                  class="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  disabled={loadingC ? true : false}
                >
                 Register
                </button>

                <p class="text-sm font-semibold mt-2 pt-1 mb-0"  >
                Already have an account?  
                <Link 
                to='/login-collaborator'  
                class="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out loginRegisterText">
                   Sing in
                  </Link>
                </p>

               
              </div>
            </form>
            </div>
          </div>
        </div>
      
      </>
  )
 }


 export default FormRegisterCollaborator

 