import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCollaboratorAppointments } from '../../actions/collaboratorActions';

const AppointmentsSlider = () => {
    const dispatch = useDispatch()
    const { collaboratorAppointments } = useSelector((state) => state.appointmentReducer);
    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(getCollaboratorAppointments());
          }, 8000);
          return () => clearTimeout(timer);
      }, [dispatch]);

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="flex flex-row space-x-4 overflow-x-auto">
        {collaboratorAppointments.map((appointments) => (
          <div key={appointments._id} className="w-64 border rounded-md shadow-md p-4">
            <div className="font-semibold text-lg mb-2">{appointments.name}</div>
            <div className="text-gray-500 text-sm mb-2">{appointments.startTime}</div>
            <div className="text-gray-500 text-sm mb-2">{appointments.endTime}</div>
            <div className="text-gray-500 text-sm mb-2">{appointments.description}</div>
            <div className="text-gray-500 text-sm mb-2">{appointments.address}</div>
            <div className="flex justify-end mt-4">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppointmentsSlider;