import React from 'react';
import { useSelector } from 'react-redux';
import SwiperCore, { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

const GalleryCollaborators = () => {
  const { collaborator} = useSelector(state => state.collaboratorDetails)

  let images = [];

  if (collaborator && Array.isArray(collaborator.imagesGallery) ) {
    images = collaborator.imagesGallery.filter((image) => image.url || image.secure_url)
      .map((image) => ({
        original: image.url || image.secure_url,
        thumbnail: image.url || image.secure_url,
        originalAlt: image.alt // or some other value for the alt attribute
      }));
  }

  if (!collaborator || !collaborator.imagesGallery) {
    // Display a loading spinner or error message here
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="bg-white">
        <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="text-left">
            <h2 className="text-base font-sans text-custom-red font-semibold tracking-wide uppercase">
              {collaborator.name}
            </h2>
            <p className="text-4xl text-left font-body tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              Image Gallery
            </p>
          </div>
          <ImageGallery
            items={images }
            showPlayButton={false}
            renderItem={(item) => (
              <div className="w-full h-4/5">
                <img
                  src={item.original}
                  alt={item.originalAlt}
                  className="mx-auto w-full h-96 object-fill cursor-pointer"
                  onClick={""}
                />
              </div>
            
            )}
            fullscreen={{
              
              nav: false,
              autoPlay: false,
              useBrowserFullscreen: false,
              styles: {
                closeButton: { right: '10px', top: '10px' },
                image: { maxHeight: '90vh', maxWidth: '90vw' }
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default GalleryCollaborators;
