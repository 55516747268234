import React from "react";
import SectionImgText from "./Utils/SectionImgText";


function SectionImgTextHome() {
    return (
        <>

        <SectionImgText
        title = "Our services are at your fingertips."
        text = "We strive to provide you with the best possible experience when working with us, so that you can find the best solution for your needs."
        img1 = "https://images.unsplash.com/photo-1514316454349-750a7fd3da3a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
        alt1 = "Imagen 1"
        img2 = "https://images.unsplash.com/photo-1617814076367-b759c7d7e738?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=708&q=80"
        alt2 = "Imagen 2"
        img3 = "https://images.unsplash.com/photo-1611859266238-4b98091d9d9b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80"
        alt3 = "Imagen 3"
        img4 = "https://images.unsplash.com/photo-1542406523-20963e4bb303?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
        alt4 = "Imagen 4"
        img5 = "https://images.unsplash.com/photo-1542282088-fe8426682b8f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
        alt5 = "Imagen 5"
        img6 = "https://images.unsplash.com/photo-1616422285623-13ff0162193c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=731&q=80"
        alt6 = "Imagen 6"
        img7 = "https://images.unsplash.com/photo-1550855909-58ad1e69b513?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
        alt7 = "Imagen 7"
        linkB ="Collaborators"
        />
        
        </>
    )
}

export default SectionImgTextHome