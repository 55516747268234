import 'tw-elements'
import React from 'react';
import '../Css/TailsPrueba.css'
import FrenquentQuestions from './FrequenQuestions';


 export default function FeacturesSection() {
    return (
       <>

<div class="container my-24 px-6 mx-auto">

  
  <section class="mb-32 text-gray-800">
    <div class="flex flex-wrap items-center">
      <div class="grow-0 shrink-0 basis-auto w-full lg:w-4/12 mb-6 md:mb-0 px-3">
        <h2 class="text-3xl font-body text-black font-bold mb-6">
        Frequent <span class="font-body text-custom-red">questions</span>
        </h2>

        <p class=" font-sans text-gray-700 mb-12">
       Some of the most frequent questions that customers tend to ask us, 
        check if your answer is here before contacting us, if you need more 
        information we will be happy to help you and clarify any doubts.
        </p>
      </div>

      <div class="grow-0 shrink-0 basis-auto w-full lg:w-8/12 mb-6 mb-md-0 px-3">
        <div class="flex flex-wrap">

        <FrenquentQuestions 
        questions = "What is our work schedule?"
        answers = "The platform is active 24/7, our collaborators have specific hours which you can see in the information section, our office is independent and we work from 8am to 4pm."
        />

        

        <FrenquentQuestions 
        questions = "How can I evaluate the collaborator?"
        answers = "Once the service has been performed, you must receive a notification from the collaborator with the service order so that it can be evaluated."
        />

        <FrenquentQuestions 
        questions = "How can I add my vehicle?"
        answers = "In the user profile you can save the amount you need, so then it would only be to select the one or those who need to perform the service."
        />

        <FrenquentQuestions 
        questions = "Can the collaborator see all my data?"
        answers = "This is at the customer's option as they can select the data they need to display. This can be seen in the profile settings."
        />

       <FrenquentQuestions 
        questions = "How can I know more about the collaborator?"
        answers = "The necessary data is available on the collaborators page, for more information you can visit its main page and different social networks."
        />

       <FrenquentQuestions 
        questions = "No collaborators show in my area?"
        answers = "For this case, we contact several collaborators for you to see if they can perform the service, as soon as they confirm, we put you in contact with them."
        />

        <FrenquentQuestions 
        questions = "Are they responsible for the damage caused?"
        answers = "The collaborators have different types of guarantees, which must be applied according to the case."
        />

       <FrenquentQuestions 
        questions = "A collaborator has canceled me several times, what can I do?"
        answers = "If you follow the due process through our system, you can proceed to make a report of the problem, we proceed to study the case. We are very sorry for this inconvenience, we can only proceed to put you in contact with another of our collaborators."
        />



        </div>
      </div>
    </div>
  </section>


</div>

       </>
    )
  }
  