import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { clearErrors, login } from "../../actions/collaboratorActions";
import MetaData from "../../Components/layout/MetaData";

function LoginCollaborator() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const collaboratorAuth = useSelector(state => state.collaboratorAuth);
            const { isAuthenticatedCollaborator, error } = collaboratorAuth;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

  useEffect(() => {
  

    if(isAuthenticatedCollaborator) {
       
      navigate("/home");
      
    }
  
    if (error){
     // alert.error(error);
      Swal.fire('Error en la autenticación', error, 'error');
      dispatch(clearErrors());
    }
  
  }, [ dispatch, alert, isAuthenticatedCollaborator, error])
  
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(email, password))
  }
  
  

  return (
    <>
    <MetaData title={'Login Collaborator GTM'} />
    <div className="h-screen flex flex-col justify-center items-center bg-gray-100">
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-80" onSubmit={handleSubmit}>
        <h2 className="text-3xl font-bold mb-4 text-gray-800">Welcome Collaborator!</h2>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="password">
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Sign In
          </button>
          <Link
            className="px-4 inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
            to="/register-collaborator"
          >
            Want to join? Sign up!
          </Link>
        </div>
        <div className="flex items-center justify-end mr-4">
          <Link
            className="hover:text-red-800 inline-block align-baseline font-bold text-sm text-red-500"
            to="/login"
          >
            Back to login user
          </Link>
        </div>
      </form>
    </div>
  </>
  );
}

export default LoginCollaborator;
