import axios from "axios";

const gtmApi = axios.create({
 
    baseURL: "https://glasstome.us",  //http://localhost:4000   https://glasstome.us
    headers: {
      "Content-Type": "application/json",
    },
  });


export default gtmApi