import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import StarRatings from 'react-star-ratings';
import SwiperCore, { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import Modal from "react-modal";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../../styles.css'
SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

const ReviewsCollaborators = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const collaboratorDetails = useSelector((state) => state.collaboratorDetails);
    const {  collaborator} = collaboratorDetails;

   
    const images = collaborator.reviews
  ? collaborator.reviews.flatMap((reviews) =>
      reviews.reviewImages.map((image) => ({
        original: image.url,
        thumbnail: image.url,
      }))
    )
  : [];

  const handleImageClick = (event) => {
    const selectedIndex = parseInt(event.target.dataset.index, 10);
    setSelectedImageIndex(selectedIndex);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
    <div className=" py-10 bg-gray-100 pt-15">
    <div className="text-left max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className=" text-left">
        {/* <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
        <p className="text-4xl font-body text-left tracking-tight font-bold text-black sm:text-5xl md:text-6xl">
         What People say about me!
          </p> 
        <p className="  pl-1 font-sans mt-4 max-w-2xl text-left text-xl text-gray-700 ">
        A look at reviews of our collaborators, for more information you can contact them and ask the desired questions, so that you feel even more sure of your choice. We are committed to being useful to you.
        </p>
      </div>

     
      <div  style={{height: "40vh"}} 
            className=" mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
         <Swiper
    spaceBetween={50}
    slidesPerView={1}
    pagination={ {clickable: true} }
    cssMode={true}
    navigation={true}
    autoplay={{ delay: 4000 }}
    mousewheel={true}
    keyboard={true}
    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
      
    className="  drop-shadow-2xl "
    style={{height: "100%"}}
    
  >
    {collaborator.reviews && collaborator.reviews.map((reviews) => (
       <SwiperSlide 
       className=' '
       key={reviews._id}>
      
       <div className=" max-h-96 space-y-8 sm:space-y-0 md:space-y-0 lg:space-y-0 xl:space-y-0 
          grid grid-cols-3 xl:grid-cols-4 lg:grid-cols-4  md:grid-cols-4 sm:grid-cols-3 justify-center 
          md:gap-x-1 md:gap-y-1 sm:gap-x-1 sm:gap-y-1 lg:gap-x-5 lg:gap-y-10 xl:gap-x-5 xl:gap-y-10">
        
         
         
         <div className="  justify-center col-span-2 xs:col-span-1 sm:col-span-2 lg:col-span-2  md:col-span-2 md:flex items-center mb-4">
           <div className="  pl-8 relative justify-center ml-3">
        
             <h2 className="uppercase tracking-wide  text-sm text-red-600 font-semibold">{reviews.name}</h2>
            
               <StarRatings
                 rating={reviews.rating}
                 starRatedColor="#F59E0B"
                 starDimension="20px"
                 starSpacing="1px"
               />
               <span className=" ml-2 text-gray-600">
                 {reviews.rating.toFixed(1)}
               </span>
             
             <p className=" mt-2 text-black  m-2 text-sm sm:text-xs">
          {reviews.comment}
          </p>
           </div>
         </div>
           {/* CREA UN MODAR CON LAS IMAGENES */}
      
           <div className="md:shrink-0 flex items-center ">
             {reviews.reviewImages && reviews.reviewImages.map((images, index) => (
               
               <img  
               className='sm:hidden flex relative max-h-36 hover:scale-150  h-auto'
               key={index} 
               src={images.url} 
               alt={`Reemplacement, Repair, Calibration, Windshield, Reviews ${index + 1}`}/> 
               
             ))}
           </div>  
           
         
       </div>
     </SwiperSlide>
    ))}
  </Swiper>
         
  

        </div>
        </div>

        
        </div>

    <div  className=" py-12 bg-white pt-15">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="lg:text-center">
        {/* <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
        <p className="text-4xl text-left font-body tracking-tight font-extrabold text-black sm:text-5xl md:text-6xl">
         Gallery from reviews
        </p>
        <p className="block font-sans mt-4 max-w-2xl text-left text-xl text-gray-700 ">
        Take a look at the images that customers have uploaded, nothing speaks better than actions.
         </p>
      </div>
     
  <ImageGallery
    items={images}
    showPlayButton={false}
    renderItem={(item) => (
      <div className="w-full h-full">
        <img
          src={item.original}
          alt={item.originalAlt}
          className="mx-auto w-full h-96 object-fill cursor-pointer"
          onClick={false}
        />
      </div>
    )}
  />
</div>
      </div>
      
        </>
  );
};


export default ReviewsCollaborators;