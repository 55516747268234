import React, { useEffect, useState } from 'react';

import '../../styles.css'

import Collection from '../Utils/Collections';
import Heading from '../Utils/Heading';

import PageSections from '../Utils/PageSections';
import StatsSections from '../Utils/StatsSections';


import { useDispatch, useSelector } from 'react-redux'

import MetaData from '../layout/MetaData';


import {getCollaborators} from '../../actions/collaboratorActions'

import CardCollaborators from '../../Collaborators/Components/CardCollaborators';
import Pagination from 'react-js-pagination';

import { useParams } from 'react-router-dom';

import Loading from '../../utils/Loading';





 function Collaborators() {


 // CONTADOR PARA BOTONES
 const [valor , setValor] = useState(0);
 const sumar = () => {
  setValor(valor+1)
 }
 const restablecer = () => {
  setValor(0)
 }
 const restar = () => {
  setValor(valor-1)
 }


  const dispatch = useDispatch();

  // *** PAGINATION ***
  const [currentPage, setCurrentPage] = useState(1)

  // **** SEARCH CITIES ****
  const [ city, setCity] = useState('')
  const match = useParams();
  const keyword = match
  // **** SEARCH CITIES ****
  const cities = [
    'New York',
    'New Jersey',
    'Philadelphia',
    'San Francisco',
    'Oregon'
  ]

  const allCollaborators = useSelector((state) => state.allCollaborators);
  const { loadingC, collaborators, collaboratorsCount, resPerPage,  filteredCollaboratorsCount } = allCollaborators;


  useEffect(() => {
    dispatch(getCollaborators( keyword, currentPage, city))
  }, [dispatch, keyword, currentPage, city])

  // **** PAGINATION ****
  function setCurrentPageNo(pageNumber) {
    setCurrentPage(pageNumber)
}
let count = collaboratorsCount;
     if (keyword) {
         count = filteredCollaboratorsCount
     }
//FILTER  COLLABORATORS


    return (
      <>

<MetaData title={'Best Collaborators Online'} />


       <main>

      <div className="relative overflow-hidden bg-no-repeat bg-cover headingC " >
     <Heading
        title="Find a helping hand"
        slogan="Learn more about our collaborators"
        boton= "Get Started"/>
     </div>        
        
        {/* **** Filter Cyties ****  */}
        <div className=' mx-auto lg:max-w-7xl lg:px-2 rounded-lg'>
        <div className="  grid grid-cols-2 gap-y-10 gap-x-6 xm:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:gap-x-8">
        <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline "> Choose </span>{' '}
                <span className="block text-custom-red xl:inline"> your city </span>
              </h1>
            </div>
              
            
          </main>
          <main className=" mx-auto my-auto ">
          <div className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <ul className="grid grid-row gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {cities.map((city) => (
                  <li
                    className='text-black hold:text-red hover:text-blue-600'
                    style={{
                      cursor: 'pointer',
                      listStyleType: 'none'
                    }}
                    key={city}
                    onClick={() => setCity(city)}
                  >
                    {city}
                  </li>
                ))}
              </ul>
            </div>
        </main>
  
        </div>
        </div>
 
       {loadingC ? <Loading /> : (
        <>
       


       
       {/* SHOW THE CARD COLLABORATORS */}
        <div className=' mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-2 rounded-lg'>
        <div className="  grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 xl:gap-x-8">
            {collaborators.map(collaborator => (
          <> 
          <CardCollaborators key={collaborator._id} collaborator={collaborator} col={4} className="group" />
           
           </> 
           ))}   
         </div>

        </div>


         {/* // *****  PAGINATION  ***** */}
           {resPerPage >= count && (
          <>
           <div className="-mt-24 xs:-mt-20 sm:-mt-20 lg:-mt-24 ">
                <div
                className='flex justify-center'>
                               <Pagination
                                 innerClass='

                                 lg:justify-center md:justify-center sm:justify-center justify-center
                                 rounded-lg font-[Poppins] justify-items-stretch py-10 px-10 max-w-lg mx-max space-y-2
                                 py-1 flex items-center space-y-0 space-x-1
                                 xs:py-2 xs:flex xs:items-center xs:space-y-0 xs:space-x-3
                                 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6
                                 md:py-6 md:flex md:items-center md:space-y-0 md:space-x-9
                                 lg:py-8 lg:flex lg:items-center lg:space-y-0 lg:space-x-12'
                                activePage={currentPage}
                                itemsCountPerPage={resPerPage}
                                totalItemsCount={collaboratorsCount}
                                onChange={setCurrentPageNo}
                                nextPageText={' > '}
                                prevPageText={' < '}
                                firstPageText={'First'}
                                lastPageText={'Last'}
                                itemClass=" text-slate-500 font-medium font-semibold place-items-center h-12  "
                                linkClass="justify-items-stretch
                                py-2 px-2
                                xs:py-2 xs:px-2
                                sm:py-4 sm:px-4
                                md:py-4 md:px-4
                                lg:py-4 lg:px-4
                                xl:py-6 xl:px-6
                                max-w-lg mx-max bg-white rounded-xl shadow-lg space-y-2 sm:flex sm:items-center sm:space-y-0 sm:space-x-6 border-blue-600 px-4 rounded-l-lg hover:bg-blue-600 hover:text-white"

                                activeLinkClass = "justify-items-stretch  py-2 px-2 xs:py-2 xs:px-2 sm:py-4 sm:px-4 md:py-4 md:px-4 lg:py-4 lg:px-4 xl:py-6 xl:px-6 max-w-lg mx-max bg-red rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6 border-2 hover:border-0 border-red-600 px-4 rounded-l-lg hover:bg-red-600 hover:text-white"

                            />
                     </div>
                    </div>

                        </>
                    )}  

      </>
       ) }



    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 sliderInit">

    <div className='heroSection'>

    <StatsSections />

     <PageSections />
     <Collection />
     </div>



   </div>

 </main>

 </>
  )


}

export default Collaborators


