import React from "react";
import HeroSections from "./Utils/HeroSections";



function HeroSectionsHome() {
    return (
        <>

        <HeroSections 
        title = "We aim to make these services easier to use."
        subTitle= "online business"
        text = "Our aim is to create a bridge between clients and collaborators, and we do our utmost to make it effective, simple, and professional. We ensure that our collaborators assist our clients in a responsible manner, while always respecting the agreements made."
        boton1 = "Collaborator"
        boton2 = "More Info"
        img = "https://images.unsplash.com/photo-1531921391719-ca49c9b4c810?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
        alt = "Imagen Hero"
        />
        
        </>
    )
}

export default HeroSectionsHome