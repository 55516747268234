
import React from 'react';
import '../Css/TailsPrueba.css'

 export default function Heading(props) {
    return (
       <>
        
          
 
   <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed heading1 "
      >
      <div className="flex justify-center items-center h-full">
        <div className="text-center text-white px-6 md:px-12">
          <h1 className="text-4xl font-bold font-body tracking-tight sm:text-6xl">{props.title}</h1>
          <h3 className="text-3xl font-body mb-8">{props.slogan}</h3>
          <button type="button"
            className="bg-custom-red font-sans font-bold inline-block px-6 py-2.5 border-2  text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
            data-mdb-ripple="true" data-mdb-ripple-color="light">
            {props.boton}
          </button>
        </div>
      </div>
    </div>
    
</>
    )
 }