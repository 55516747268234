import React from 'react'
import { Link } from 'react-router-dom'
//import { Button } from './Button'
import './Footer.css';


function Footer() {
  return (
    <div className='footer-container'>
        {/* <section className=' footer-subscription'>
            <p className='font-bold text-xl font-sans'>
            Stay updated, do not miss our offers.
            </p>

            <p className='font-body text-base'>
                You can unsubscribe at any time.
            </p>

            <di className='input-areas'>
                <form>
                    <input 
                    type='email' 
                    name='email' 
                    placeholder='Your Email' 
                    className='footer-input' 
                    />
                    <Button buttonStyle=''>
                        Subscribe
                    </Button>
                </form>
            </di>
        </section> */}

        <div className='footer-links'>
            <div className='footer-link-wrapper'>
                
                <div className='footer-link-items '>
                    {/* <h2>About us</h2> */}

                    
                    <Link 
                    className='font-sans text-lg'
                    to='/terms-&-conditions'>
                        Terms & Conditions
                    </Link>
                    {/* <Link to='/'>Careers</Link>
                    <Link to='/'>Inventors</Link>
                    <Link to='/'>Terms of Service</Link> */}

                </div>

                <div className='footer-link-items'>
                    {/* <h2>About us</h2> */}

                    <Link 
                    className='font-sans text-lg'
                    to='/privacy-policy'>
                        Our politics
                    </Link>
                </div>

                
            </div>

            <div className='footer-link-wrapper'>
                
                <div className='footer-link-items'>
                    {/* <h2>Contact Us</h2> */}

                    <Link 
                    className='font-sans text-lg hover:text-custom-red'
                    to='frequent-questions'>
                        Frequent questions
                    </Link>
                

                </div>

                 <div className='footer-link-items'>
                    {/* <h2>About us</h2> */}

                   
                    <Link className='font-sans text-lg' to='/sign-in'>Testimonials</Link>
                    {/* <Link to='/'>Careers</Link>
                    <Link to='/'>Inventors</Link>
                    <Link to='/'>Terms of Service</Link> */}

                </div> 

                
            </div>

        </div>

        <section className='social-media'>
            <div className='social-media-wrap'>
                <div className='footer-logo'>
                    <Link to='/' className='social-logo font-body'>
                         Glass To Me {/*<i className='fab fa-typo3' /> */}
                    </Link>
                </div>
                <small className='website-rights font-body'>All rights reserved </small>
               
                {/* <div className='social-icons'>
                    <Link className='text-custom-red social-icon-link facebook'
                          to='/'
                          target='_blank'
                          aria-label='Facebook'
                          >
                        <i class="fa-brands fa-facebook"></i>
                    </Link>
                    <Link className='text-custom-red social-icon-link instagram'
                          to='/'
                          target='_blank'
                          aria-label='Instagram'
                          >
                        
                        <i className=" fa-brands fa-instagram"></i>
                    </Link>
                    <Link className='text-custom-red  social-icon-link twitter'
                          to='/'
                          target='_blank'
                          aria-label='Twitter'
                          >
                        <i class="fa-brands fa-twitter-square"></i>
                    </Link>
                    <Link className='text-custom-red social-icon-link linkedin'
                          to='/'
                          target='_blank'
                          aria-label='LinkedIn'
                          >
                        <i class="fa-brands fa-linkedin"></i>
                    </Link>
                    
                </div> */}
            </div>
        </section>
    </div>
  )
}

export default Footer