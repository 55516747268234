import React from 'react';
import MetaData from '../layout/MetaData';

function PrivacyPolicy() {
  return (
   <>
   <MetaData title={'Our Privacy Policy'} />
        
      
      
        
        <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 relative content-center" >    
   
   <h1  className="text-4xl font-bold text-gray-900 mb-4" >Privacy Policy for Glass to Me</h1>

<p className="text-lg text-gray-700 mb-6">At glasstome.com, accessible from https://glasstome.com/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by glasstome.com and how we use it.</p>

<p className="text-lg text-gray-700 mb-6">If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

<p className="text-lg text-gray-700 mb-6">This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in glasstome.com. This policy is not applicable to any information collected offline or via channels other than this website.</p>

<h2  className="text-2xl font-bold text-gray-800 mb-6">Consent</h2>

<p className="text-lg text-gray-700 mb-6">By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

<h2 className="text-2xl font-bold text-gray-800 mb-6">Information we collect</h2>

<p className="text-lg text-gray-700 mb-6">The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
<p className="text-lg text-gray-700 mb-6">If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
<p className="text-lg text-gray-700 mb-6">When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>

<h2 className="text-2xl font-bold text-gray-800 mb-6">How we use your information</h2>

<p className="text-lg text-gray-700 mb-6">We use the information we collect in various ways, including to:</p>

<ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-3">
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">Provide, operate, and maintain our website </h3></li>
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">Improve, personalize, and expand our website</h3></li>
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">Understand and analyze how you use our website</h3></li>
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">Develop new products, services, features, and functionality</h3></li>

<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">Send you emails</h3></li>
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">Find and prevent fraud</h3></li>
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.</h3></li>
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">We may use cookies, web beacons, and other tracking technologies to collect information about your use of our website, including your IP address, browser type, operating system, and other technical information.</h3></li>
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">We may share your information with third-party service providers who help us provide and improve our website, such as web hosting companies, analytics providers, and marketing partners.</h3></li>
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">We may disclose your information if required by law or if we believe that such disclosure is necessary to comply with legal processes or government requests, to protect our rights, property, or safety, or to prevent illegal activities or violations of our terms of use.</h3></li>
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">We may transfer your information to a successor entity in the event of a merger, acquisition, or bankruptcy involving our company.</h3></li>
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">We may aggregate and anonymize your information to create statistical data that does not identify you and that we may use for any purpose.</h3></li>
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">We may obtain your consent before using your information for certain purposes or before sharing it with third parties in ways not described in this privacy policy.</h3></li>
<li className="bg-white shadow rounded-lg p-6"> <h3 className="text-xl font-semibold text-gray-900 mb-2">If you have any questions or concerns about how we use your information, please contact us at: <a>info@glasstome.com</a>.</h3></li>

</ul>

<h2 className="text-2xl font-bold text-gray-800 mb-6">Log Files</h2>

<p className="text-lg text-gray-700 mb-6">glasstome.com follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

<h2  className="text-2xl font-bold text-gray-800 mb-6">Cookies and Web Beacons</h2>

<p className="text-lg text-gray-700 mb-6">Like any other website, glasstome.com uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
<p className="text-lg text-gray-700 mb-6">We may use both session cookies, which expire when you close your browser, and persistent cookies, which remain on your device until they expire or you delete them. These cookies may be set by us or by third-party service providers we use for website analytics, advertising, and other purposes.</p>
<p className="text-lg text-gray-700 mb-6">We may use web beacons, which are small transparent images embedded in web pages or email messages, to collect information about your use of our website or to track the effectiveness of our marketing campaigns.</p>
<p className="text-lg text-gray-700 mb-6">You can control or disable cookies and web beacons through your browser settings. However, please note that disabling cookies may limit your ability to use certain features of our website.</p>
<p className="text-lg text-gray-700 mb-6">We may use cookies and web beacons to collect information for advertising purposes, such as to show you personalized ads based on your interests and browsing behavior. You can opt-out of certain types of targeted advertising by visiting the Network Advertising Initiative's opt-out page or the Digital Advertising Alliance's opt-out page.</p>
<p className="text-lg text-gray-700 mb-6">We may use cookies and web beacons to collect information for website analytics purposes, such as to track the number of visitors to our website, the pages they visit, and the time they spend on our website. We may use this information to improve our website and our services.</p>
<p className="text-lg text-gray-700 mb-6">We may use cookies and web beacons to collect information for other purposes, such as to remember your login credentials, to save your preferences, or to provide you with relevant content and recommendations.</p>
<p className="text-lg text-gray-700 mb-6">By using our website, you consent to our use of cookies and web beacons in accordance with this privacy policy. If you do not consent to our use of cookies and web beacons, you should disable them through your browser settings or refrain from using our website.</p>



<h2 className="text-2xl font-bold text-gray-800 mb-6">Advertising Partners Privacy Policies</h2>

<p className="text-lg text-gray-700 mb-6"> You may consult this list to find the Privacy Policy for each of the advertising partners of glasstome.com. </p>

<p className="text-lg text-gray-700 mb-6">Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on glasstome.com, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

<p className="text-lg text-gray-700 mb-6">Note that glasstome.com has no access to or control over these cookies that are used by third-party advertisers.</p>
<p className="text-lg text-gray-700 mb-6">Therefore, we recommend that you review the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

<h2 className="text-2xl font-bold text-gray-800 mb-6">Third Party Privacy Policies</h2>

<p className="text-lg text-gray-700 mb-6">glasstome.com's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

<p className="text-lg text-gray-700 mb-6">You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

<h2 className="text-2xl font-bold text-gray-800 mb-6">CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

<p className="text-lg text-gray-700 mb-6">Under the CCPA, among other rights, California consumers have the right to:</p>
<p className="text-lg text-gray-700 mb-6">Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
<p className="text-lg text-gray-700 mb-6">Request that a business delete any personal data about the consumer that a business has collected.</p>
<p className="text-lg text-gray-700 mb-6">Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
<p className="text-lg text-gray-700 mb-6">If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
<p className="text-lg text-gray-700 mb-6">Please note that these rights are only applicable to California residents and apply to personal information collected within the scope of the CCPA.</p>
<p className="text-lg text-gray-700 mb-6">If you are a California resident, you also have the right to opt-out of the sale of your personal information. Please note that we do not sell personal information of our users.</p>
<p className="text-lg text-gray-700 mb-6">If you would like to exercise any of your CCPA privacy rights, please contact us through the contact information provided on our website. We may ask you to provide certain information to verify your identity before processing your request.</p>

<h2 className="text-2xl font-bold text-gray-800 mb-6">GDPR Data Protection Rights</h2>

<p className="text-lg text-gray-700 mb-6">We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
<p className="text-lg text-gray-700 mb-6">The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
<p className="text-lg text-gray-700 mb-6">The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
<p className="text-lg text-gray-700 mb-6">The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
<p className="text-lg text-gray-700 mb-6">The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
<p className="text-lg text-gray-700 mb-6">The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
<p className="text-lg text-gray-700 mb-6">The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
<p className="text-lg text-gray-700 mb-6">If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
<p className="text-lg text-gray-700 mb-6">Additionally, we want to remind you that you have the right to lodge a complaint with a supervisory authority if you believe that our processing of your personal data violates applicable data protection laws. We encourage you to contact us first if you have any concerns about how we process your personal data so that we can address them promptly.</p>

<h2 className="text-2xl font-bold text-gray-800 mb-6">Children's Information</h2>

<p className="text-lg text-gray-700 mb-6">Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

<p className="text-lg text-gray-700 mb-6">glasstome.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

<h2 className="text-2xl font-bold text-gray-800 mb-6">Changes to This Privacy Policy</h2>

<p className="text-lg text-gray-700 mb-6">We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>
<p className="text-lg text-gray-700 mb-6">Additionally, it is important to note that if you continue to use our website after any changes to the Privacy Policy, you are agreeing to the updated terms. We also recommend that you check the date of the last revision to ensure that you are aware of any recent changes. If you do not agree with the changes made to the Privacy Policy, you should stop using our website immediately.</p>

<p className="text-lg text-gray-700 mb-6">Our Privacy Policy was created with the help of the <a className='link-secondary' href="https://www.termsfeed.com/privacy-policy-generator/">TermsFeed Privacy Policy Generator</a>.</p>

<h2 className="text-2xl font-bold text-gray-800 mb-6">Contact Us</h2>

<p className="text-lg text-gray-700 mb-6">If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
   </div>

   </>
  );
}

export default PrivacyPolicy;