export const LOGIN_COLLABORATOR_REQUEST = 'LOGIN_COLLABORATOR_REQUEST'
export const LOGIN_COLLABORATOR_SUCCESS = 'LOGIN_COLLABORATOR_SUCCESS'
export const LOGIN_COLLABORATOR_FAIL = 'LOGIN_COLLABORATOR_FAIL'

export const REGISTER_COLLABORATOR_REQUEST = 'REGISTER_COLLABORATOR_REQUEST'
export const REGISTER_COLLABORATOR_SUCCESS = 'REGISTER_COLLABORATOR_SUCCESS'
export const REGISTER_COLLABORATOR_FAIL = 'REGISTER_COLLABORATOR_FAIL'

export const ADMIN_COLLABORATORS_SUCCESS = 'ADMIN_COLLABORATORS_SUCCESS'
export const ADMIN_COLLABORATORS_REQUEST = 'ADMIN_COLLABORATORS_REQUEST'
export const ADMIN_COLLABORATORS_FAIL = 'ADMIN_COLLABORATORS_FAIL'

export const LOAD_COLLABORATOR_REQUEST = 'LOAD_COLLABORATOR_REQUEST'
export const LOAD_COLLABORATOR_SUCCESS = 'LOAD_COLLABORATOR_SUCCESS'
export const LOAD_COLLABORATOR_FAIL = 'LOAD_COLLABORATOR_FAIL'

export const UPDATE_PROFILE_COLLABORATOR_REQUEST = 'UPDATE_PROFILE_COLLABORATOR_REQUEST'
export const UPDATE_PROFILE_COLLABORATOR_SUCCESS = 'UPDATE_PROFILE_COLLABORATOR_SUCCESS'
export const UPDATE_PROFILE_COLLABORATOR_RESET = 'UPDATE_PROFILE_COLLABORATOR_RESET'
export const UPDATE_PROFILE_COLLABORATOR_FAIL = 'UPDATE_PROFILE_COLLABORATOR_FAIL'

export const UPDATE_PASSWORD_COLLABORATOR_REQUEST = 'UPDATE_PASSWORD_COLLABORATOR_REQUEST'
export const UPDATE_PASSWORD_COLLABORATOR_SUCCESS = 'UPDATE_PASSWORD_COLLABORATOR_SUCCESS'
export const UPDATE_PASSWORD_COLLABORATOR_RESET = 'UPDATE_PASSWORD_COLLABORATOR_RESET'
export const UPDATE_PASSWORD_COLLABORATOR_FAIL = 'UPDATE_PASSWORD_COLLABORATOR_FAIL'

export const FORGOT_PASSWORD_COLLABORATOR_REQUEST = 'FORGOT_PASSWORD_COLLABORATOR_REQUEST'
export const FORGOT_PASSWORD_COLLABORATOR_SUCCESS = 'FORGOT_PASSWORD_COLLABORATOR_SUCCESS'
export const FORGOT_PASSWORD_COLLABORATOR_FAIL = 'FORGOT_PASSWORD_COLLABORATOR_FAIL'

export const NEW_PASSWORD_COLLABORATOR_REQUEST = 'NEW_PASSWORD_COLLABORATOR_REQUEST'
export const NEW_PASSWORD_COLLABORATOR_SUCCESS = 'NEW_PASSWORD_COLLABORATOR_SUCCESS'
export const NEW_PASSWORD_COLLABORATOR_FAIL = 'NEW_PASSWORD_COLLABORATOR_FAIL'

export const ALL_COLLABORATORS_REQUEST = 'ALL_COLLABORATORS_REQUEST'
export const ALL_COLLABORATORS_SUCCESS = 'ALL_COLLABORATORS_SUCCESS'
export const ALL_COLLABORATORS_FAIL = 'ALL_COLLABORATORS_FAIL'

export const COLLABORATOR_DETAILS_REQUEST = 'COLLABORATOR_DETAILS_REQUEST'
export const COLLABORATOR_DETAILS_SUCCESS = 'COLLABORATOR_DETAILS_SUCCESS'
export const COLLABORATOR_DETAILS_FAIL = 'COLLABORATOR_DETAILS_FAIL'

export const UPDATE_COLLABORATOR_REQUEST = 'UPDATE_COLLABORATOR_REQUEST'
export const UPDATE_COLLABORATOR_SUCCESS = 'UPDATE_COLLABORATOR_SUCCESS'
export const UPDATE_COLLABORATOR_RESET = 'UPDATE_COLLABORATOR_RESET'
export const UPDATE_COLLABORATOR_FAIL = 'UPDATE_COLLABORATOR_FAIL'

export const DELETE_COLLABORATOR_REQUEST = 'DELETE_COLLABORATOR_REQUEST'
export const DELETE_COLLABORATOR_SUCCESS = 'DELETE_COLLABORATOR_SUCCESS'
export const DELETE_COLLABORATOR_RESET = 'DELETE_COLLABORATOR_RESET'
export const DELETE_COLLABORATOR_FAIL = 'DELETE_COLLABORATOR_FAIL'

export const LOGOUT_COLLABORATOR_SUCCESS = 'LOGOUT_COLLABORATOR_SUCCESS'
export const LOGOUT_COLLABORATOR_FAIL = 'LOGOUT_COLLABORATOR_FAIL'

export const NEW_REVIEW_REQUEST = 'NEW_REVIEW_REQUEST'
export const NEW_REVIEW_SUCCESS = 'NEW_REVIEW_SUCCESS'
export const NEW_REVIEW_RESET = 'NEW_REVIEW_RESET'
export const NEW_REVIEW_FAIL = 'NEW_REVIEW_FAIL'

export const NEW_APPOINTMENT_REQUEST = 'NEW_APPOINTMENT_REQUEST'
export const NEW_APPOINTMENT_SUCCESS ='NEW_APPOINTMENT_SUCCESS'
export const NEW_APPOINTMENT_RESET ='NEW_APPOINTMENT_RESET'
export const NEW_APPOINTMENT_FAIL = 'NEW_APPOINTMENT_FAIL'

export const GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST'
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS'
export const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL'

export const GET_APPOINTMENT_REQUEST = 'GET_APPOINTMENT_REQUEST'
export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS'
export const GET_APPOINTMENT_FAIL = 'GET_APPOINTMENT_FAIL'

export const DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST'
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS'
export const DELETE_REVIEW_RESET = 'DELETE_REVIEW_RESET'
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL'


export const DELETE_APPOINTMENT_REQUEST = 'DELETE_APPOINTMENT_REQUEST'
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS'
export const DELETE_APPOINTMENT_RESET = 'DELETE_APPOINTMENT_RESET' 
export const DELETE_APPOINTMENT_FAIL = 'DELETE_APPOINTMENT_FAIL'  

export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS'
export const CREATE_APPOINTMENT_FAIL = 'CREATE_APPOINTMENT_FAIL'
export const CLEAR_ERRORS_COLLABORATOR = 'CLEAR_ERRORS_COLLABORATOR'