import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import { Button } from './Button';
import './Navbar.css';

import { Fragment } from 'react'
import {  Menu, Transition } from '@headlessui/react'
import { BellIcon, } from '@heroicons/react/outline'

import { useDispatch, useSelector } from 'react-redux';
import { loadUser, logout } from '../actions/userActions';
import Swal from 'sweetalert2';
import store from '../store/storeGTM';
import { loadCollaborator, logoutCollaborator } from '../actions/collaboratorActions';




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function Navbar() {
  const dispatch = useDispatch();

 

  const logoutHandler = () => {
   dispatch(logout());
   dispatch(logoutCollaborator());
    Swal.fire('Logged out successfully.')
  }

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960){
      setButton(false);
    } else {
      setButton(true);
    }
  };


//  const auth = useSelector(state => state.auth);
//  const { user, isAuthenticated } = auth;
  const { user, isAuthenticated, loading, } = useSelector(state => state.auth)
  const { collaborator, isAuthenticatedCollaborator, loadingC } = useSelector(state => state.collaboratorAuth)
 
  //const collaboratorAuth = useSelector((state) => state.collaboratorAuth);
 // const { collaborator, isAuthenticatedCollaborator, loadingC } = collaboratorAuth;

    // ******** LOAD USER OR COLLABORATOR  *********
    useEffect(() => {
      showButton();
        store.dispatch(loadCollaborator());
        store.dispatch(loadUser());
     
    }, []);
  
  
  
  

  window.addEventListener('resize', showButton);
  // Resto del código

    return (
        <>
          <nav className='navbar'>
            <div className='navbar-container'>
              <Link to='/' className='navbar-logo font-bold font-mono' onClick={closeMobileMenu}>
                Glass To Me
                {/* <i className='fab fa-typo3 ' /> */}
              </Link>
              <div className='menu-icon' onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
              </div>
             
              <ul className={click ? 'nav-menu active ' : 'nav-menu '}>
                
                <li className='nav-item'>
                  <Link to='home' className='font-body nav-links' onClick={closeMobileMenu}>
                    Home
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='collaborators'
                    className='font-body nav-links'
                    onClick={closeMobileMenu}
                  >
                    Collaborators
                  </Link>
                </li>

                {/* <li className='nav-item'>
                  <Link
                    to='collaboratorsR'
                    className='font-body nav-links'
                    onClick={closeMobileMenu}
                  >
                    CollaboratorsR
                  </Link>
                </li> */}

                <li className='nav-item'>
                  <Link
                    to='about-us'
                    className='font-body nav-links'
                    onClick={closeMobileMenu}
                  >
                    About Us
                  </Link>
                </li>
    
                
                {isAuthenticatedCollaborator || isAuthenticated ? (
  <>
    <li className='nav-item'>
      <Link
        to={isAuthenticatedCollaborator ? '/collaborator-me' : '/me'}
        className='font-body nav-links-mobile border-0 text-blue-500 font-light'
        onClick={closeMobileMenu}
      >
        {isAuthenticatedCollaborator ? collaborator?.name : user?.name}
      </Link>
    </li>
    <li className='nav-item'>
      <a href='/' className='font-body nav-links-mobile' onClick={logoutHandler}>
        Logout
      </a>
    </li>
  </>
) : (
  <li className='nav-item'>
    <Link to='/login' className='font-body nav-links-mobile' onClick={closeMobileMenu}>
      LogIn
    </Link>
  </li>
)}



              
                 
              </ul>
              
              {!isAuthenticated && !isAuthenticatedCollaborator &&  button && 
               
              <Button > 
                <Link to='/login'><span className='font-sans text-base text-custom-red'> Sign In </span></Link> 
              </Button> 
              }
              
             
              {((!isAuthenticated || !isAuthenticatedCollaborator) && button) && (
  <div className="md:block">
    <div className="ml-4 flex items-center lg:ml-6">
      {/* ... Otras partes del menú ... */}

      <Menu as="div" className="ml-3 relative">
      <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
  {isAuthenticated || collaborator ? (
    <>
      <span className='block px-4 py-2 text-base font-serif font-semibold text-black bg-gray-100 border-b-black border-b-2 underline-offset-4'>
        {isAuthenticated ? user?.name : collaborator?.name }
      </span>
      <img
        className="h-8 w-8 rounded-full"
        src={isAuthenticated ? user?.avatar?.url : collaborator?.avatar[0]?.url }
        alt={isAuthenticated ? user?.name : collaborator?.name}
        // onError={handleImageError}
      />
    </>
  ) : null}
</Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-sm shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <>
                  {isAuthenticated || isAuthenticatedCollaborator ? (
                    <>
                      <Link
                        to={isAuthenticatedCollaborator ? "/collaborator-me" : "/me"}
                        className={classNames(
                          active ? 'bg-gray-100 border-b-black border-b-2 underline-offset-4' : '',
                          'block px-4 py-2 text-sm text-blue-500 hover:text-blue-800'
                        )}
                      >
                        Profile
                      </Link>
                      {isAuthenticatedCollaborator && (
                        <Link
                          to="/collaborator-me"
                          className={classNames(
                            active ? 'bg-gray-100 border-b-black border-b-2 underline-offset-4' : '',
                            'block px-4 py-2 text-sm text-green-500 hover:text-green-800'
                          )}
                        >
                          Dashboard
                        </Link>
                      )}
                      <a
                        href="/logout"
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-red-500 hover:text-custom-red'
                        )}
                        onClick={logoutHandler} // Asegúrate de que logoutHandler esté definido y maneje correctamente el logout
                      >
                        Logout
                      </a>
                    </>
                  ) : (
                    <>
                      <Link
                        to='/login'
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        )}
                      >
                        Login
                      </Link>
                      <Link
                        to='/signup'
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        )}
                      >
                        Sign up
                      </Link>
                    </>
                  )}
                </>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  </div>
)}


            </div>
           
          </nav>
        </>
      );
    }
    
    export default Navbar;