import React from 'react';
import { useSelector } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Link } from 'react-router-dom';



const GalleryClients = () => {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  let images = [];

  if (user && user.carImages) {
    images = user.carImages.filter((image) => image.url || image.secure_url)
      .map((image) => ({
        original: image.url || image.secure_url,
        thumbnail: image.url || image.secure_url,
      }));
  }

  if (!user || !user.carImages) {
    // Display a loading spinner or error message here
    return <>
            <div>
              <span className='font-sans font-semibold text-black'>Dont hav images...  
                <Link 
                className='text-base font-sans text-green-700 hover:text-custom-red font-semibold tracking-wide uppercase'
                to={'/me/update'}> Edit user for upload car images 
                </Link> 
              
              </span>
          
          </div>;
          </>
  }

  return (
    <>
      <div className="bg-white">
        <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="text-left">
            <h2 className="text-base font-sans text-custom-red font-semibold tracking-wide uppercase">
              {user.name}
            </h2>
            <p className="text-4xl text-left font-body tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              Image Gallery
            </p>
          </div>
          <ImageGallery
            items={images}
            showPlayButton={false}
            renderItem={(item) => (
              <div className="w-full h-full">
                <img
                  src={item.original}
                  alt={item.originalAlt}
                  className="mx-auto w-full h-full object-fill cursor-pointer"
                  onClick={""}
                />
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default GalleryClients;
