import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MetaData from '../../Components/layout/MetaData'
import Loading from '../../utils/Loading'
import GalleryClients from '../Components/GalleryClients'





const Profile = () => {
  //  const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const { user, loading } = auth;

    
    return (
      <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <MetaData title={'Your Profile'} />
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 ">
          <p className=" m-6 text-4xl text-left font-body tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              My Profile
            </p>
          
          <div className="flex flex-wrap justify-around mt-5 user-info">
            <div className="w-full md:w-3/12">
              <figure className=" m-8 justify-end ">
                <img className="rounded-full object-cover w-48 h-48" src={user.avatar?.url || "" } alt={user.name} />
              </figure>
              <Link
                to="/me/update"
                id="edit_profile"
                className="block w-full text-center py-3 mt-5 font-medium text-white bg-green-700 rounded-md hover:bg-green-800 focus:outline-none focus:bg-green-700 transition duration-150 ease-in-out"
              >
                Update Profile
              </Link>

              <figure className=" m-8 justify-end ">
                
                <img className="rounded-md object-cover w-48 h-48" src={user.vinNumberCarImage?.url || ""} alt={user.name} />
                <p className="text-lg font-sans text-left font-bold mb-5">Image VIN</p>
              </figure>
             
            </div>
    
            <div className="w-full font-sans font-bold md:w-5/12 mt-5 md:mt-0 md:ml-5">
              <h4 className="text-gray-600">User Name</h4>
              {user.name ? (
                  <p className="text-lg font-medium mb-5">{user.name}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Name</p>
                )}
    
              <h4 className="text-gray-600">Email </h4>
              <p className="text-lg font-medium mb-5">{user.email}</p>
    
              <h4 className="text-gray-600">Address</h4>
              {user.address? (
                  <p className="text-lg font-medium mb-5">{user.address}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Addres</p>
                )}
             

              <h4 className="text-gray-600">Stete \ Province</h4>
              {user.city ? (
                  <p className="text-lg font-medium mb-5">{user.city}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Stete \ Province</p>
                )}

              <h4 className="text-gray-600">City</h4>
              {user.country ? (
                  <p className="text-lg font-medium mb-5">{user.country}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add City</p>
                )}

              <h4 className="text-gray-600">Postal Code</h4>
              {user.postalCode ? (
                  <p className="text-lg font-medium mb-5">{user.postalCode}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Postal code</p>
                )}

              <h4 className="text-gray-600">VIN</h4>
              {user.vinNumberCar ? (
                  <p className="text-lg font-medium mb-5">{user.vinNumberCar}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add VIN</p>
                )}
              <h4 className="text-gray-600">Cellphone</h4>
              {user.cellNo ? (
                  <p className="text-lg font-medium mb-5">{user.cellNo}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Cellphone</p>
                )}

              <h4 className="text-gray-600">Telephone</h4>
              {user.phoneNo ? (
                  <p className="text-lg font-medium mb-5">{user.phoneNo}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Telephone</p>
                )}

              <h4 className="text-gray-600">Telephone 2</h4>
              {user.officeNo ? (
                  <p className="text-lg font-medium mb-5">{user.officeNo}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Telephone 2</p>
                )}

              
    
             
    
              <Link
                to="/password/update"
                className="block w-full text-center py-3 mt-3 font-medium text-white bg-red-700 rounded-md hover:bg-red-500 focus:outline-none focus:bg-custom-red transition duration-150 ease-in-out"
              >
                Change Password
              </Link>
            </div>
          </div>
          
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 " >
          <GalleryClients />
          </div>
         
          
          </div>
        </>
      )}
    </>
    )
}

export default Profile
