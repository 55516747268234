import {
    LOGIN_COLLABORATOR_REQUEST,
    LOGIN_COLLABORATOR_SUCCESS,
    LOGIN_COLLABORATOR_FAIL,
    REGISTER_COLLABORATOR_REQUEST,
    REGISTER_COLLABORATOR_SUCCESS,
    REGISTER_COLLABORATOR_FAIL,
    LOAD_COLLABORATOR_REQUEST,
    LOAD_COLLABORATOR_SUCCESS,
    LOAD_COLLABORATOR_FAIL,
    UPDATE_PASSWORD_COLLABORATOR_REQUEST,
    UPDATE_PASSWORD_COLLABORATOR_SUCCESS,
    UPDATE_PASSWORD_COLLABORATOR_RESET,
    UPDATE_PASSWORD_COLLABORATOR_FAIL,
    UPDATE_PROFILE_COLLABORATOR_REQUEST,
    UPDATE_PROFILE_COLLABORATOR_SUCCESS,
    UPDATE_PROFILE_COLLABORATOR_RESET,
    UPDATE_PROFILE_COLLABORATOR_FAIL,
    FORGOT_PASSWORD_COLLABORATOR_REQUEST,
    FORGOT_PASSWORD_COLLABORATOR_SUCCESS,
    FORGOT_PASSWORD_COLLABORATOR_FAIL,
    NEW_PASSWORD_COLLABORATOR_REQUEST,
    NEW_PASSWORD_COLLABORATOR_SUCCESS,
    NEW_PASSWORD_COLLABORATOR_FAIL,
    ALL_COLLABORATORS_REQUEST,
    ALL_COLLABORATORS_SUCCESS,
    ADMIN_COLLABORATORS_REQUEST,
    ADMIN_COLLABORATORS_SUCCESS,
    ADMIN_COLLABORATORS_FAIL,
    ALL_COLLABORATORS_FAIL,
    COLLABORATOR_DETAILS_REQUEST,
    COLLABORATOR_DETAILS_SUCCESS,
    COLLABORATOR_DETAILS_FAIL,
    UPDATE_COLLABORATOR_REQUEST,
    UPDATE_COLLABORATOR_SUCCESS,
    UPDATE_COLLABORATOR_RESET,
    UPDATE_COLLABORATOR_FAIL,
    DELETE_COLLABORATOR_REQUEST,
    DELETE_COLLABORATOR_SUCCESS,
    DELETE_COLLABORATOR_RESET,
    DELETE_COLLABORATOR_FAIL,
    LOGOUT_COLLABORATOR_SUCCESS,
    LOGOUT_COLLABORATOR_FAIL,
    NEW_REVIEW_REQUEST,
    NEW_REVIEW_SUCCESS,
    NEW_REVIEW_RESET,
    NEW_REVIEW_FAIL,
    GET_REVIEWS_REQUEST,
    GET_REVIEWS_SUCCESS,
    GET_REVIEWS_FAIL,
    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_RESET,
    DELETE_REVIEW_FAIL,
    CLEAR_ERRORS_COLLABORATOR,
    NEW_APPOINTMENT_REQUEST,
    NEW_APPOINTMENT_SUCCESS,
    NEW_APPOINTMENT_FAIL,
    NEW_APPOINTMENT_RESET,
    GET_APPOINTMENT_REQUEST,
    GET_APPOINTMENT_SUCCESS,
    GET_APPOINTMENT_FAIL,
    DELETE_APPOINTMENT_RESET,
    DELETE_APPOINTMENT_FAIL,
    DELETE_APPOINTMENT_SUCCESS,
    DELETE_APPOINTMENT_REQUEST
} from '../constants/collaboratorConstants'


export const collaboratorAuthReducer = (state = { collaborator: {} }, action) => {
    switch (action.type) {

        case LOGIN_COLLABORATOR_REQUEST:
        case REGISTER_COLLABORATOR_REQUEST:
        case LOAD_COLLABORATOR_REQUEST:
            return {
                loadingC: true,
                isAuthenticatedCollaborator: false,
            }

        case LOGIN_COLLABORATOR_SUCCESS:
        case REGISTER_COLLABORATOR_SUCCESS:
        case LOAD_COLLABORATOR_SUCCESS:
            return {
                ...state,
                loadingC: false,
                isAuthenticatedCollaborator: true,
                collaborator: action.payload.collaborator
            }

        case LOGOUT_COLLABORATOR_SUCCESS:
            return {
                loadingC: false,
                isAuthenticatedCollaborator: false,
                collaborator: null
            }

        case LOAD_COLLABORATOR_FAIL:
            return {
                loadingC: false,
                isAuthenticatedCollaborator: false,
                collaborator: null,
                error: action.payload
            }

        case LOGOUT_COLLABORATOR_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case LOGIN_COLLABORATOR_FAIL:
        case REGISTER_COLLABORATOR_FAIL:
            return {
                ...state,
                loadingC: false,
                isAuthenticatedCollaborator: false,
                collaborator: null,
                error: action.payload
            }

        case CLEAR_ERRORS_COLLABORATOR:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const collaboratorReducer = (state = {}, action) => {
    switch (action.type) {

        case UPDATE_PROFILE_COLLABORATOR_REQUEST:
        case UPDATE_PASSWORD_COLLABORATOR_REQUEST:
        case UPDATE_COLLABORATOR_REQUEST:
        case DELETE_COLLABORATOR_REQUEST:
            return {
                ...state,
                loadingC: true
            }

        case UPDATE_PROFILE_COLLABORATOR_SUCCESS:
        case UPDATE_PASSWORD_COLLABORATOR_SUCCESS:
        case UPDATE_COLLABORATOR_SUCCESS:
            return {
                ...state,
                loadingC: false,
                isUpdated: action.payload
            }

        case DELETE_COLLABORATOR_SUCCESS:
            return {
                ...state,
                loadingC: false,
                isDeleted: action.payload
            }

        case UPDATE_PROFILE_COLLABORATOR_RESET:
        case UPDATE_PASSWORD_COLLABORATOR_RESET:
        case UPDATE_COLLABORATOR_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case DELETE_COLLABORATOR_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case UPDATE_PROFILE_COLLABORATOR_FAIL:
        case UPDATE_PASSWORD_COLLABORATOR_FAIL:
        case UPDATE_COLLABORATOR_FAIL:
        case DELETE_COLLABORATOR_FAIL:
            return {
                ...state,
                loadingC: false,
                error: action.payload
            }

        case CLEAR_ERRORS_COLLABORATOR:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const forgotPasswordCollaboratorReducer = (state = {}, action) => {
    switch (action.type) {

        case FORGOT_PASSWORD_COLLABORATOR_REQUEST:
        case NEW_PASSWORD_COLLABORATOR_REQUEST:
            return {
                ...state,
                loadingC: true,
                error: null
            }

        case FORGOT_PASSWORD_COLLABORATOR_SUCCESS:
            return {
                ...state,
                loadingC: false,
                message: action.payload
            }

        case NEW_PASSWORD_COLLABORATOR_SUCCESS:
            return {
                ...state,
                success: action.payload
            }

        case FORGOT_PASSWORD_COLLABORATOR_FAIL:
        case NEW_PASSWORD_COLLABORATOR_FAIL:
            return {
                ...state,
                loadingC: false,
                error: action.payload
            }

        case CLEAR_ERRORS_COLLABORATOR:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const allCollaboratorsReducer = (state = { collaborators: [] }, action) => {
    switch (action.type) {

        case ALL_COLLABORATORS_REQUEST:
            return {
                ...state,
                loadingC: true,
                collaborators: []
            }

        case ALL_COLLABORATORS_SUCCESS:
            return {
                ...state,
                loadingC: false,
                collaborators: action.payload.collaborators,
                collaboratorsCount: action.payload.collaboratorsCount,
                resPerPage: action.payload.resPerPage,
                filteredCollaboratorsCount: action.payload.filteredCollaboratorsCount
            }

        case ALL_COLLABORATORS_FAIL:
            return {
                ...state,
                loadingC: false,
                error: action.payload
            }

        case CLEAR_ERRORS_COLLABORATOR:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}


export const collaboratorDetailsReducer = (state = { collaborator : {} }, action) => {
    switch (action.type) {

        case COLLABORATOR_DETAILS_REQUEST:
            return {
                ...state,
                loadingC: true,
            }

        case COLLABORATOR_DETAILS_SUCCESS:
            return {
                ...state,
                loadingC: false,
                collaborator: action.payload.collaborator
            }

        case COLLABORATOR_DETAILS_FAIL:
            return {
                ...state,
                loadingC: false,
                error: action.payload.collaborator
            }

        case CLEAR_ERRORS_COLLABORATOR:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

//REVIEW NEW 
export const newReviewReducer = (state = {}, action) => {
    switch (action.type) {
      case NEW_REVIEW_REQUEST:
        return {
          ...state,
          loadingC: true,
          success: false,
          error: null,
          review: null
        };
      case NEW_REVIEW_SUCCESS:
        return {
          ...state,
          loadingC: false,
          success: action.payload.success,
          error: null,
          review: action.payload.review
        };
      case NEW_REVIEW_FAIL:
        return {
          ...state,
          loadingC: false,
          success: false,
          error: action.payload,
          review: null
        };
      case NEW_REVIEW_RESET:
        return {
          ...state,
          success: false,
          error: null,
          review: null
        };
      case CLEAR_ERRORS_COLLABORATOR:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  

export const collaboratorReviewsReducer = (state = { review: [] }, action) => {
    switch (action.type) {

        case GET_REVIEWS_REQUEST:
            return {
                ...state,
                loadingC: true
            }

        case GET_REVIEWS_SUCCESS:
            return {
                loadingC: false,
                reviews: action.payload.reviews
            }

        case GET_REVIEWS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS_COLLABORATOR:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const reviewReducer = (state = {}, action) => {
    switch (action.type) {

        case DELETE_REVIEW_REQUEST:
            return {
                ...state,
                loadingC: true
            }

        case DELETE_REVIEW_SUCCESS:
            return {
                ...state,
                loadingC: false,
                isDeleted: action.payload
            }

        case DELETE_REVIEW_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_REVIEW_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS_COLLABORATOR:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

//APPOINTMENT NEW 
export const newAppointmentReducer = (state = {}, action) => {
    switch (action.type) {
      case NEW_APPOINTMENT_REQUEST:
        return {
          ...state,
          loadingC: true,
          success: false,
          error: null,
          appointment: null
        };
      case NEW_APPOINTMENT_SUCCESS:
        return {
          ...state,
          loadingC: false,
          success: action.payload.success,
          error: null,
          appointment: action.payload.appointment
        };
      case NEW_APPOINTMENT_FAIL:
        return {
          ...state,
          loadingC: false,
          success: false,
          error: action.payload,
          appointment: null
        };
      case NEW_APPOINTMENT_RESET:
        return {
          ...state,
          success: false,
          error: null,
          appointment: null
        };
      case CLEAR_ERRORS_COLLABORATOR:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  

  const initialState = {
    loading: false,
    error: null,
    collaboratorAppointments: [],
  };
  
  export const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'APPOINTMENTS_REQUEST':
        return { ...state, loading: true };
  
      case 'APPOINTMENTS_SUCCESS':
        return { ...state, loading: false, collaboratorAppointments: action.payload };
  
      case 'APPOINTMENTS_FAIL':
        return { ...state, loading: false, error: action.payload };
  
      default:
        return state;
    }
  };


// export const appointmentReducer = (state = {}, action) => {
//     switch (action.type) {

//         case DELETE_APPOINTMENT_REQUEST:
//             return {
//                 ...state,
//                 loadingC: true
//             }

//         case DELETE_APPOINTMENT_SUCCESS:
//             return {
//                 ...state,
//                 loadingC: false,
//                 isDeleted: action.payload
//             }

//         case DELETE_APPOINTMENT_FAIL:
//             return {
//                 ...state,
//                 error: action.payload
//             }

//         case DELETE_APPOINTMENT_RESET:
//             return {
//                 ...state,
//                 isDeleted: false
//             }

//         case CLEAR_ERRORS_COLLABORATOR:
//             return {
//                 ...state,
//                 error: null
//             }

//         default:
//             return state
//     }
// }