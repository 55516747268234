import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import MetaData from '../../Components/layout/MetaData'
import Loading from '../../utils/Loading'

import { FaAward, FaCalendarDay, FaCarSide, FaCcMastercard, FaCertificate, FaClock, FaFileContract, FaLaptopCode, FaMoneyBill, FaMoneyCheckAlt, FaRegCalendarCheck, FaRegWindowRestore, FaShippingFast, FaStar, FaTools, FaTruck, FaWarehouse } from 'react-icons/fa'
import GalleryCollaboratorsProfile from '../Components/GalleryCollaboratorProfile'






const ProfileCollaborator = () => {
  //  const dispatch = useDispatch();
  const { collaborator, isAuthenticatedCollaborator, loadingC } = useSelector(state => state.collaboratorAuth)

    
    return (
      <>
      {loadingC ? (
        <Loading />
      ) : (
        <>
          <MetaData title={ collaborator.name + ' Profile'} />
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 ">
          <p className=" m-6 text-4xl text-left font-body tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
             {collaborator.name}
            </p>
          
           
              

          <div className="flex flex-wrap justify-around mt-5 user-info">
          
              
            <div className="w-full md:w-3/12">
              <figure className=" m-8 justify-end ">
              {collaborator.avatar && collaborator.avatar.map(avatar => [
                 <img
                 className="h-ful w-full "
                 src={avatar.url}
                
                 alt="Auto-Glass"
               />
          ])}  
              </figure>
              <Link
                to="/collaborator-me/update"
                id="edit_profile"
                className="block w-full text-center py-3 mt-5 font-medium text-white bg-green-700 rounded-md hover:bg-green-800 focus:outline-none focus:bg-green-700 transition duration-150 ease-in-out"
              >
                Update Profile
              </Link>
              <Link
                to="/collaborator-me/dashboard"
                id="dashboard"
                className="block w-full text-center py-3 mt-5 font-medium text-white bg-blue-700 rounded-md hover:bg-blue-800 focus:outline-none focus:bg-blue-700 transition duration-150 ease-in-out"
              >
                Dashboard
              </Link>

             
             
            </div>
    
            <div className="w-full font-sans font-bold md:w-5/12 mt-5 md:mt-0 md:ml-5">
            <div className=" border-b-gray-600 border-b-2 pb-2  justify-around mx-4 grid grid-flow-col auto-cols-max">
              <div className=" justify-start  grid grid-flow-col auto-cols-max">
       
              <p className='pl-2 text-sm text-yellow-500 hover:text-red-700 mt-auto'>{ `${(collaborator.ratings / 5) * 100}%` } </p>
              <FaStar className=' hover:text-red-700 text-yellow-500 h-5 w-5' />      
              </div>
                
               {/* <p className='text-sm text-yellow-500 hover:text-red-700 mt-auto'>{(collaborators.ratings / 5) * 100}%</p> */}
               <span className="text-sm text-gray-500 ml-2">({collaborator.numOfReviews} Reviews)</span>
              </div>
              <h4 className="text-gray-600">User Name</h4>
              {collaborator.name ? (
                  <p className="text-lg font-medium mb-5">{collaborator.name}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Name</p>
                )}
    
              <h4 className="text-gray-600">Email </h4>
              <p className="text-lg font-medium mb-5">{collaborator.email}</p>
    
              <h4 className="text-gray-600">Slogan</h4>
              {collaborator.slogan? (
                  <p className="text-lg font-medium mb-5">{collaborator.slogan}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Slogan</p>
                )}
             

              <h4 className="text-gray-600 flex-auto flex-col">Service Area</h4>
              {collaborator.city ? (
                  <p className="text-lg font-medium mb-5 mx-2 ">{collaborator.city}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add service area</p>
                )}

              
              <h4 className="text-gray-600">Locartion</h4>
              {collaborator.postalCode ? (
                  <p className="text-lg font-medium mb-5">{collaborator.postalCode}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Location</p>
                )}

             
              <h4 className="text-gray-600">Cellphone</h4>
              {collaborator.phoneNo ? (
                  <p className="text-lg font-medium mb-5">{collaborator.phoneNo}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Cellphone</p>
                )}

              <h4 className="text-gray-600">Telephone</h4>
              {collaborator.phoneNo ? (
                  <p className="text-lg font-medium mb-5">{collaborator.phoneNo}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Telephone</p>
                )}

              <h4 className="text-gray-600">Telephone 2</h4>
              {collaborator.phoneNo ? (
                  <p className="text-lg font-medium mb-5">{collaborator.phoneNo}</p>
                ) : (
                  <p className="text-lg font-medium mb-5" >Update you profile to add Telephone 2</p>
                )}
      
   
             
    
              <Link
                to="/password/update"
                className="block w-full text-center py-3 mt-3 font-medium text-white bg-red-700 rounded-md hover:bg-red-500 focus:outline-none focus:bg-custom-red transition duration-150 ease-in-out"
              >
                Change Password
              </Link>
            </div>
          </div>
          
          <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 '>
                      {/*PERFORMANCE SECTION COLLABORATOR*/}

    
     <div className="py-12 bg-white  pt-15">
      <div className="text-left max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className=" text-left">
          {/* <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
          <p className="text-5xl sm:text-4xl md:text-5xl font-body text-left tracking-tight font-extrabold text-black ">
             Skills & Performance
          </p>
          <p className="text-3xl sm:text-2xl md:text-3xl pl-1 font-sans mt-4 max-w-2xl text-left text-gray-700 ">
          Update your profile so customers can see the new changes.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-8 sm:space-y-0 md:space-y-0 lg:space-y-0 xl:space-y-0 
                        grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4  md:grid-cols-3 sm:grid-cols-2 
                        md:gap-x-5 md:gap-y-10 sm:gap-x-5 sm:gap-y-10 lg:gap-x-5 lg:gap-y-10 xl:gap-x-5 xl:gap-y-10">
           
              {collaborator.performanceCollaborator && collaborator.performanceCollaborator.map(performanceCollaborator => [
              
              
              performanceCollaborator.oEMGlass ? (
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                  <FaCarSide className=' h-7 w-7'/>
                  </div>
                  <p className="pt-3 ml-16 font-body text-lg leading-6 font-medium text-gray-900">OEM Glass </p>
                </dt>
              </div> 
              ):(
                <></>
              ),
              performanceCollaborator.warranty ? (
                <div className="relative">
                  <dt className=''>
                    <div className=" absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                    <FaAward  className='h-7 w-7'/>
                    </div>
                    <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Warranty </p>
                  </dt>
                </div> 
                ):(
                  <></>
                ),
                performanceCollaborator.replacement ? (
                  <div className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                      <FaTools className='h-7 w-7'/>
                      </div>
                      <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Replacement </p>
                    </dt>
                  </div> 
                  ):(
                    <></>
                  ),  
                  performanceCollaborator.insurance ? (
                    <div className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                        <FaFileContract className='h-7 w-7'/>
                        </div>
                        <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Insurance </p>
                      </dt>
                    </div> 
                    ):(
                      <></>
                    ),
                    performanceCollaborator.repair ? (
                      <div className="relative">
                        <dt>
                          <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                          <FaRegWindowRestore className='h-7 w-7'/>
                          </div>
                          <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Repair </p>
                        </dt>
                      </div> 
                      ):(
                        <></>
                      ),

                  performanceCollaborator.calibration ? (
                    <div className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                        <FaLaptopCode className='h-7 w-7'/>
                        </div>
                        <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Calibration </p>
                      </dt>
                    </div> 
                    ):(
                      <></>
                    ),
                    performanceCollaborator.nextDayService ? (
                      <div className="relative">
                        <dt>
                          <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                          <FaRegCalendarCheck className='h-7 w-7'/>
                          </div>
                          <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Next Day Service </p>
                        </dt>
                      </div> 
                      ):(
                        <></>
                      ),
                      performanceCollaborator.registerAGRSS ? (
                        <div className="relative">
                          <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                            <FaCertificate className='h-7 w-7'/>
                            </div>
                            <p className="pt-3 ml-16 text-lg leading-6 font-body font-medium text-gray-900"> Regidter AGRSS </p>
                          </dt>
                        </div> 
                        ):(
                          <></>
                        ),
                        performanceCollaborator.freeMobileService ? (
                          <div className="relative">
                            <dt>
                              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                              <FaShippingFast className='h-7 w-7'/>
                              </div>
                              <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Free Mobile Service </p>
                            </dt>
                          </div> 
                          ):(
                        <></>
                      ),
                      performanceCollaborator.localServices ? (
                        <div className="relative">
                          <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                            <FaWarehouse className='h-7 w-7'/>
                            </div>
                            <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Local Services </p>
                          </dt>
                        </div> 
                        ):(
                          <></>
                        ),
                        performanceCollaborator.deliveryServices ? (
                          <div className="relative">
                            <dt>
                              <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                                  <FaTruck className='h-7 w-7'/>
                                  </div>
                                  <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900"> Delivery Services </p>
                                </dt>
                              </div> 
                              ):(
                                <></>
                              ),  
              ])}
        
       
              
          </dl>
        </div>
      </div>
    </div>

      {/*INFORMCATION SECTION COLLABORATOR*/}
    
      <div className="py-12 bg-white pt-15">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          {/* <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
          <p className="text-5xl sm:text-4xl md:text-5xl text-left tracking-tight font-body font-extrabold text-black ">
            Schelude information
          </p>
          
        </div>

        <div className="mt-10">
          <dl className="
            space-y-8 sm:space-y-0 md:space-y-0 lg:space-y-0 xl:space-y-0 
            grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3  md:grid-cols-3 sm:grid-cols-2 
            md:gap-x-5 md:gap-y-10 sm:gap-x-5 sm:gap-y-10 lg:gap-x-5 lg:gap-y-10 xl:gap-x-5 xl:gap-y-10
          ">    
           
          {collaborator?.scheduleDays?.mondayToFriday ? ( 
            <>
             <div  className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white">
                    <FaCalendarDay className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-sans font-medium text-gray-900">Schelude Information</p>
                </dt>
                <dd className="mt-2 ml-16 text-base font-body text-gray-500 lining-nums "> Monday <big className='text-black text-base'> To </big> Friday </dd> 
               
              </div>
            </>
           ):(
            <></>
           )}
           

           { collaborator?.scheduleDays?.mondayToSaturday ? ( 
            <>
               <div  className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white">
                    <FaCalendarDay className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg font-sans leading-6 font-medium text-gray-900">Days Information</p>
                </dt>
                <dd className="mt-2 ml-16 text-base font-body text-gray-500 lining-nums ">Monday <big className='text-black text-base'> To</big> Saturday</dd> 
               
              </div>
            </>
           ):(
            <></>
           )}
           

           { collaborator?.scheduleDays?.sunday ? ( 
            <>
               <div  className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white">
                    <FaCalendarDay className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg font-sans leading-6 font-medium text-gray-900">Special Day Information</p>
                </dt>
                <dd className="mt-2 ml-16 text-body text-gray-500 lining-nums "> Work 
                <big className='text-black text-base '> on  </big> 
                 Sunday 
                </dd> 
                 
              </div>
            </>
           ):(
            <></>
           )}
           
          
            <>
               <div  className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white">
                    <FaClock className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg font-sans leading-6 font-medium text-gray-900">Schelude Infomation</p>
                </dt>
                <dd className=" mt-2 text-black text-base lining-nums ml-16 grid grid-flow-col auto-cols-max ">
                  {collaborator?.scheduleDays?.hoursMToSInit} 
                  <big className=' ml-3 font-body text-base text-gray-500 '> AM </big>
                  <p className='mx-2'> at </p>
                  {collaborator?.scheduleDays?.hoursMToSExit}
                  <big className='ml-3 font-body text-base text-gray-500'> PM </big>
                </dd> 
                 
              </div>
            </>
          
 
        

    </dl>
        </div>
      </div>
    </div>
      
      {/*  ****** PAYMENT METHOD *****  */}
<div className=" py-6 bg-white  pt-15 mb-8">
      <div className="text-left max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className=" text-left">
          {/* <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
          <p className=" text-left font-body tracking-tight font-extrabold text-black text-5xl sm:text-4xl md:text-5xl">
          Payment method 
          </p>
         
        </div>

        <div className="mt-10">
          <dl className="space-y-8 sm:space-y-0 md:space-y-0 lg:space-y-0 xl:space-y-0 
                        grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4  md:grid-cols-3 sm:grid-cols-2 
                        md:gap-x-5 md:gap-y-10 sm:gap-x-5 sm:gap-y-10 lg:gap-x-5 lg:gap-y-10 xl:gap-x-5 xl:gap-y-10">
          
        {collaborator?.paymentMethod?.cash ? (
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-700  text-white">
                  <FaMoneyBill className=' h-7 w-7'/>
                  </div>
                  <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900">Cash </p>
                </dt>
              </div> 
              ):(
                <></>
              ) }
          {collaborator?.paymentMethod?.card ? (
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white">
                  <FaCcMastercard className=' h-7 w-7'/>
                  </div>
                  <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900">Card </p>
                </dt>
              </div> 
              ):(
                <></>
              ) }
      {collaborator?.paymentMethod?.check ? (
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-700 text-white">
                  <FaMoneyCheckAlt className=' h-7 w-7'/>
                  </div>
                  <p className="pt-3 ml-16 text-lg font-body leading-6 font-medium text-gray-900">Check </p>
                </dt>
              </div> 
              ):(
                <></>
              ) }
           </dl>
           </div>
           </div>
           </div>
              
    
          </div>
         
         
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 " >
          <GalleryCollaboratorsProfile />
          </div>
         
          
          </div>
        </>
      )}
    </>
    )
}

export default ProfileCollaborator
