
import React from "react"
const callouts = [
    {
      name: 'Repair',
      description: 'Glass repair of all types of vehicles.',
      imageSrc: 'https://images.unsplash.com/photo-1473289495063-6d095b0302a9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1079&q=80',
      imageAlt: 'windshield, backwindos, front-left, front-right, quarter window. back-left window, back right window.',
      href: '#',
    },
    {
      name: 'Replacement',
      description: 'Glass replacement for your vehicle.',
      imageSrc: 'https://images.unsplash.com/photo-1613042964418-89c800809319?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1192&q=80',
      imageAlt: 'windshield, backwindos, front-left, front-right, quarter window. back-left window, back right window.',
      href: '#',
    },
    {
      name: 'Calibration',
      description: 'Sensors and cameras of your vehicle.',
      imageSrc: 'https://images.unsplash.com/photo-1610647752706-3bb12232b3ab?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1025&q=80',
      imageAlt: 'windshield, backwindos, front-left, front-right, quarter window. back-left window, back right window.',
      href: '#',
    },
    {
      name: 'Payments',
      description: 'Payments in cash, checks, insurance, transfer.',
      imageSrc: 'https://images.unsplash.com/photo-1550565118-3a14e8d0386f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      imageAlt: 'windshield, backwindos, front-left, front-right, quarter window. back-left window, back right window.',
      href: '#',
    },
    {
      name: 'Free Delivery',
      description: 'more information with our collaborators.',
      imageSrc: 'https://images.unsplash.com/photo-1551825687-f9de1603ed8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80',
      imageAlt: 'windshield, backwindos, front-left, front-right, quarter window. back-left window, back right window.',
      href: '#',
    },
    {
      name: 'Local Location',
      description: 'More information with our collaborators.',
      imageSrc: 'https://images.unsplash.com/photo-1587573578274-184d5ccabfc3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80',
      imageAlt: 'windshield, backwindos, front-left, front-right, quarter window. back-left window, back right window.',
      href: '#',
    },
    
  ]
  
  export default function Collection() {
    return (
      <div className="">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto py-16 sm:py-24 lg:py-32 lg:max-w-none">
            <h2 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">Performance Skills</h2>
  
            <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
              {callouts.map((callout) => (
                <div key={callout.name} className="group relative">
                  <div className="relative w-full h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                    <img
                      src={callout.imageSrc}
                      alt={callout.imageAlt}
                      className="w-full h-full object-center object-cover"
                    />
                  </div>
                  <h3 className="mt-6 text-sm  text-custom-red">
                    <a
                    className="font-bold text-md"
                    href={callout.href}>
                      <span className="absolute inset-0" />
                      {callout.name}
                    </a>
                  </h3>
                  <p className="text-base font-body font-semibold text-gray-900 pb-8">{callout.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  