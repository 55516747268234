
import Cookies from 'js-cookie'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import axiosConfig from '../api/axiosConfig'
import {
    LOGIN_COLLABORATOR_REQUEST,
    LOGIN_COLLABORATOR_SUCCESS,
    LOGIN_COLLABORATOR_FAIL,
    REGISTER_COLLABORATOR_REQUEST,
    REGISTER_COLLABORATOR_SUCCESS,
    REGISTER_COLLABORATOR_FAIL,
    LOAD_COLLABORATOR_REQUEST,
    LOAD_COLLABORATOR_SUCCESS,
    LOAD_COLLABORATOR_FAIL,
    UPDATE_PROFILE_COLLABORATOR_REQUEST,
    UPDATE_PROFILE_COLLABORATOR_SUCCESS,
    UPDATE_PROFILE_COLLABORATOR_FAIL,
    UPDATE_PASSWORD_COLLABORATOR_REQUEST,
    UPDATE_PASSWORD_COLLABORATOR_SUCCESS,
    UPDATE_PASSWORD_COLLABORATOR_FAIL,
    FORGOT_PASSWORD_COLLABORATOR_REQUEST,
    FORGOT_PASSWORD_COLLABORATOR_SUCCESS,
    FORGOT_PASSWORD_COLLABORATOR_FAIL,
    NEW_PASSWORD_COLLABORATOR_REQUEST,
    NEW_PASSWORD_COLLABORATOR_SUCCESS,
    NEW_PASSWORD_COLLABORATOR_FAIL,
    ALL_COLLABORATORS_REQUEST,
    ALL_COLLABORATORS_SUCCESS,
    ALL_COLLABORATORS_FAIL,
    COLLABORATOR_DETAILS_REQUEST,
    COLLABORATOR_DETAILS_SUCCESS,
    COLLABORATOR_DETAILS_FAIL,
    UPDATE_COLLABORATOR_REQUEST,
    UPDATE_COLLABORATOR_SUCCESS,
    UPDATE_COLLABORATOR_FAIL,
    DELETE_COLLABORATOR_REQUEST,
    DELETE_COLLABORATOR_SUCCESS,
    DELETE_COLLABORATOR_FAIL,
    LOGOUT_COLLABORATOR_SUCCESS,
    LOGOUT_COLLABORATOR_FAIL,
    NEW_REVIEW_REQUEST,
    NEW_REVIEW_SUCCESS,
    NEW_REVIEW_FAIL,
    GET_REVIEWS_REQUEST,
    GET_REVIEWS_SUCCESS,
    GET_REVIEWS_FAIL,
    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_RESET,
    DELETE_REVIEW_FAIL,
    CLEAR_ERRORS_COLLABORATOR,
    CREATE_APPOINTMENT_SUCCESS,
    CREATE_APPOINTMENT_FAIL,
    NEW_APPOINTMENT_SUCCESS,
    NEW_APPOINTMENT_FAIL,
    NEW_APPOINTMENT_REQUEST,
    GET_APPOINTMENT_REQUEST,
    GET_APPOINTMENT_SUCCESS,
    GET_APPOINTMENT_FAIL
} from '../constants/collaboratorConstants'
import gtmApi from '../api/gtmApi'

// Login
export const login = (email, password) => async (dispatch) => {
    try {

        dispatch({ type: LOGIN_COLLABORATOR_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await gtmApi.post('/api/v1/collaborator/login', { email, password }, config)
        Cookies.set('token', data.token, { expires: 7});
            
            localStorage.setItem('collaboratorInfo' , JSON.stringify(data.collaborator));

        dispatch({
            type: LOGIN_COLLABORATOR_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: LOGIN_COLLABORATOR_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        })
    }
}

// Register collaborator
export const register = (collaboratorData) => async (dispatch) => {
    try {

        dispatch({ type: REGISTER_COLLABORATOR_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const { data } = await gtmApi.post('/api/v1/register', collaboratorData, config)

        dispatch({
            type: REGISTER_COLLABORATOR_SUCCESS,
            payload: data.collaborator
        })

    } catch (error) {
        dispatch({
            type: REGISTER_COLLABORATOR_FAIL,
            payload: error.response.data.message
        })
    }
}

// Load collaborator
export const loadCollaborator = () => async (dispatch) => {
    try {
        dispatch({ type: LOAD_COLLABORATOR_REQUEST });

        const { data } = await gtmApi.get(`/api/v1/collaborator-user/me`);

        // Verificar si la respuesta contiene datos de usuario
        if (data && data.collaborator) {
            dispatch({
                type: LOAD_COLLABORATOR_SUCCESS,
                payload: data
            });
        } else {
            // Si no se encuentra el usuario, despachar con payload nulo
            dispatch({
                type: LOAD_COLLABORATOR_SUCCESS,
                payload: null
            });
        }
    } catch (error) {
        dispatch({
            type: LOAD_COLLABORATOR_FAIL,
            payload: error.response.data.message
        });
    }
};

// Update profile
export const updateProfile = (collaboratorData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_PROFILE_COLLABORATOR_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const { data } = await gtmApi.put('/collaborator-user/me/update', collaboratorData, config)

        dispatch({
            type: UPDATE_PROFILE_COLLABORATOR_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROFILE_COLLABORATOR_FAIL,
            payload: error.response.data.message
        })
    }
}

// Update password
export const updatePassword = (passwords) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_PASSWORD_COLLABORATOR_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await gtmApi.put('/api/v1/password/update', passwords, config)

        dispatch({
            type: UPDATE_PASSWORD_COLLABORATOR_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PASSWORD_COLLABORATOR_FAIL,
            payload: error.response.data.message
        })
    }
}

// Forgot password
export const forgotPassword = (email) => async (dispatch) => {
    try {

        dispatch({ type: FORGOT_PASSWORD_COLLABORATOR_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await gtmApi.post('/api/v1/password/forgot', email, config)

        dispatch({
            type: FORGOT_PASSWORD_COLLABORATOR_SUCCESS,
            payload: data.message
        })

    } catch (error) {
        dispatch({
            type: FORGOT_PASSWORD_COLLABORATOR_FAIL,
            payload: error.response.data.message
        })
    }
}

// Reset password
export const resetPassword = (token, passwords) => async (dispatch) => {
    try {

        dispatch({ type: NEW_PASSWORD_COLLABORATOR_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await gtmApi.put(`/api/v1/password/reset/${token}`, passwords, config)

        dispatch({
            type: NEW_PASSWORD_COLLABORATOR_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: NEW_PASSWORD_COLLABORATOR_FAIL,
            payload: error.response.data.message
        })
    }
}

// Logout collaborator
export const logoutCollaborator = () => async (dispatch) => {
    try {

        await gtmApi.get('/api/v1/logout')

        dispatch({
            type: LOGOUT_COLLABORATOR_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: LOGOUT_COLLABORATOR_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all collaborators
export const getCollaborators = (keyword = '', currentPage = 1, city) => async (dispatch) => {
    try {

        dispatch({ type: ALL_COLLABORATORS_REQUEST })

        let link = `/api/v1/collaborators?keyword=${keyword}&page=${currentPage}`
        if (city) {
            link = `/api/v1/collaborators?keyword=${keyword}&page=${currentPage}&city=${city}`
        }
        const { data } = await gtmApi.get(link)

        dispatch({
            type: ALL_COLLABORATORS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_COLLABORATORS_FAIL,
            payload: error.response.data.message
        })
    }
}

// Update collaborator - ADMIN
export const updateCollaborator = (id, collaboratorData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_COLLABORATOR_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await gtmApi.put(`/api/v1/admin/collaborator/${id}`, collaboratorData, config)

        dispatch({
            type: UPDATE_COLLABORATOR_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_COLLABORATOR_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get collaborator details - ADMIN
export const getCollaboratorDetails = (id) => async (dispatch) => {
    try {

        dispatch({ 
            type: COLLABORATOR_DETAILS_REQUEST 
        });


        setTimeout(async () => {
            const { data } = await gtmApi.get(`/api/v1/collaborator/${id}`);
      
            dispatch({
              type: COLLABORATOR_DETAILS_SUCCESS,
              payload: data ,
            });
          }, 5000); 

    } catch (error) {
        dispatch({
            type: COLLABORATOR_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete collaborator - ADMIN
export const deleteCollaborators = (id) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_COLLABORATOR_REQUEST })

        const { data } = await gtmApi.delete(`/api/v1/admin/collaborator/${id}`)

        dispatch({
            type: DELETE_COLLABORATOR_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_COLLABORATOR_FAIL,
            payload: error.response.data.message
        })
    }
}
//REVIEWS 'Content-Type': 'application/json'  'Content-Type': 'multipart/form-data', 
export const newReview = (formData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_REVIEW_REQUEST })
        
      //  reviewData.append('path', 'reviewsImages' , 'collaboratorId')
        
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const { data } = await gtmApi.put(`/api/v1/review`, formData, config)

        dispatch({
            type: NEW_REVIEW_SUCCESS,
            payload: data.success.payload
        })

    } catch (error) {
        dispatch({
            type: NEW_REVIEW_FAIL,
            payload: error.response.data.message
        })
    }
}

    

export const getCollaboratorReviews = (id) => async (dispatch) => {
    try {

        dispatch({ type: GET_REVIEWS_REQUEST })

        const { data } = await gtmApi.get(`/api/v1/reviews?id=${id}`)

        dispatch({
            type: GET_REVIEWS_SUCCESS,
            payload: data.reviews
        })

    } catch (error) {

        dispatch({
            type: GET_REVIEWS_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete collaborator review
export const deleteReview = (id, collaboratorId) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_REVIEW_REQUEST })

        const { data } = await gtmApi.delete(`/api/v1/reviews?id=${id}&collaboratorId=${collaboratorId}`)

        dispatch({
            type: DELETE_REVIEW_SUCCESS,
            payload: data.success
        })

    } catch (error) {

        console.log(error.response);

        dispatch({
            type: DELETE_REVIEW_FAIL,
            payload: error.response.data.message
        })
    }
}

// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS_COLLABORATOR
    })
}


//CREATE CALENDAR APPOINTMENT
export const createAppointment = (formData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_APPOINTMENT_REQUEST })
        
      //  reviewData.append('path', 'reviewsImages' , 'collaboratorId')
        
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const { data } = await gtmApi.post(`/api/v1/appointment`, formData, config)

        dispatch({
            type: NEW_APPOINTMENT_SUCCESS,
            payload: data.success.payload
        })

    } catch (error) {
        dispatch({
            type: NEW_APPOINTMENT_FAIL,
            payload: error.response.data.message
        })
    }
  };

  export const getCollaboratorAppointments = (id) => async (dispatch) => {
    try {

        dispatch({ type: GET_APPOINTMENT_REQUEST })

        const { data } = await gtmApi.get(`/api/v1/appointments?id=${id}`)

        dispatch({
            type: GET_APPOINTMENT_SUCCESS,
            payload: data.appointments
        })

    } catch (error) {

        dispatch({
            type: GET_APPOINTMENT_FAIL,
            payload: error.response.data.message
        })
    }
}