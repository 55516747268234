import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MetaData from '../../Components/layout/MetaData'
import Loading from '../../utils/Loading'

import { toast } from 'react-toastify'
import axios from 'axios'





const UpdateProfile = () => {

  const { user, isAuthenticated, loading } = useSelector(state => state.auth)

  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState('/images/avatars/anonymous-avatar-icon-25.png');
  const [vinNumberCar, setVinNumberCar] = useState('');
  const [vinNumberCarImage, setVinNumberCarImage] = useState(null);
  const [vinNumberCarImagePreview, setVinNumberCarImagePreview] = useState(null);
  const [carImages, setCarImages] = useState([]);
  const [carImagesPreview, setCarImagesPreview] = useState([]);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [officeNo, setOfficeNo] = useState('');
  const [cellNo, setCellNo] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.set('name', name ||  user && user.name);
    formData.set('email', email || user && user.email);
    formData.set('vinNumberCar', vinNumberCar || user && user.vinNumberCar);
    formData.set('address', address || user && user.address);
    formData.set('city', city || user && user.city);
    formData.set('cellNo', cellNo || user && user.cellNo);
    formData.set('phoneNo', phoneNo || user && user.phoneNo);
    formData.set('officeNo', officeNo || user && user.officeNo);
    formData.set('postalCode', postalCode || user && user.postalCode);
    formData.set('country', country || user && user.country);
    if (avatar) {
      formData.append('avatar', avatar );
    }
    if (carImages) {
      for (let i = 0; i < carImages.length; i++) {
        formData.append('carImages', carImages[i]);
      }
    }
    if (vinNumberCarImage) {
      formData.append('vinNumberCarImage', vinNumberCarImage);
    }

    try {
      const response = await axios.put('/api/v1/me/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <>
      { !isAuthenticated ? <Loading /> : (
        <>
        <MetaData title={user && user.name} />
        
        
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 sliderInit">
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                <p className="mt-1 text-sm text-gray-600">
                  Personal information, these are the only ones we request for the management of our service, please do not introduce credit cards. We do not request this information on the page or via email.
                  </p>
                  <p className="mt-1 text-sm text-gray-600">
                  When uploading the images, keep in mind that we only request the necessary ones, such as the VIN or glass that you need to repair.
                  </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form action="#" method="PUT" onSubmit={submitHandler}>
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                          Name:
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="given-name"
                          placeholder={user && user.name}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="vin-number" className="block text-sm font-medium text-gray-700">
                          Your VIN:
                        </label>
                        <input
                          type="text"
                          name="vinNumberCar"
                          id="vinNumberCar"
                          placeholder={user && user.vinNumberCar}
                          value={vinNumberCar}
                          onChange={(e) => setVinNumberCar(e.target.value)}
                          autoComplete="off"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-4">
                        <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                         Email:
                        </label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          value={email}onChange={(e) => setEmail(e.target.value)}
                          autoComplete="email"
                          placeholder= {user && user.email}
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      
        
                      {/* <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                          Country
                        </label>
                        <select
                          id="country"
                          name="country"
                          autoComplete="country-name"
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option>United States</option>
                          <option>Canada</option>
                          <option>Mexico</option>
                        </select>
                      </div> */}
                       <div className="col-span-6">
                          <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                            Street address
                          </label>
                          <input
                            type="text"
                            name="address"
                            id="address"
                            placeholder={user && user.address}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            autoComplete="street-address"
                            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
        
                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                          City
                        </label>
                        <input
                          type="text"
                          name="city"
                          id="city"
                          placeholder={user && user.country}
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          autoComplete="address-level2"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                          State / Province
                        </label>
                        <input
                          type="text"
                          name="region"
                          id="region"
                          placeholder={user && user.city}
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          autoComplete="address-level1"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">
                          ZIP / Postal code
                        </label>
                        <input
                          type="text"
                          name="postalCode"
                          id="postalCode"
                          placeholder={user && user.postalCode}
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                          autoComplete="postalCode"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                        {/* NUMEROS DE CONTACTO */}
        
                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label htmlFor="cellNo" className="block text-sm font-medium text-gray-700">
                          You number Cellphone
                        </label>
                        <input
                          type="tel"
                          name="cellNo"
                          id="cellNo"
                          placeholder={user && user.cellNo}
                          value={cellNo}
                          onChange={(e) => setCellNo(e.target.value)}
                          autoComplete="tel"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="officeNo" className="block text-sm font-medium text-gray-700">
                          Contact 1
                        </label>
                        <input
                          type="tel"
                          name="officeNo"
                          id="officeNo"
                          placeholder={user && user.officeNo}
                          value={officeNo}
                          onChange={(e) => setOfficeNo(e.target.value)}
                          autoComplete="tel"
                          className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
        
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label htmlFor="phoneNo" className="block text-sm font-medium text-gray-700">
                         Contact 2
                        </label>
                        <input
                         type="tel"
                         name="phoneNo"
                         id="phoneNo"
                         placeholder={user && user.phoneNo}
                         value={phoneNo}
                         onChange={(e) => setPhoneNo(e.target.value)}
                         autoComplete="tel"
                         className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
        
                {/* GALERIA DE IMAGENES, VIN NUMBER, CAR IMAGES, VIN NUMBER IMAGE */}
                  <div className="hidden sm:block" aria-hidden="true">
                   <div className="py-5">
                     <div className="hidden border-t border-gray-200" />
                   </div>
                  </div>
              
         <div className="col-span-6 sm:col-span-3 lg:col-span-2">
        <label className="block  text-sm font-medium text-gray-700">
        Avatar:
        <input
                     className='m-4 font-body text-gray-900'
                     type='file'
                     name='avatar'
                     onChange={(e) => setAvatar(e.target.files[0])} 
                    // onChange={avatarChangeHandler}
                     /> <span className=' text-xs text-green-600 font-thin'> Only can upload 1 image </span>
        </label>
        {avatarPreview && (
        <div className='hidden avatar-preview'>
        <img
          className='rounded-full h-14 w-14 mt-4'
                       src={avatarPreview}
                       alt='Avatar Preview' />
        </div>
        )}
        
        <br></br><br></br>
        
        <label className="block text-sm font-medium text-gray-700">
        VIN Image:
        <input
                     className='m-4 font-body text-gray-900'
                     type='file'
                    
                     name='vinNumberCarImage'
                     onChange={(e) => setVinNumberCarImage(e.target.files[0])} /> <span className=' text-xs text-green-600 font-thin'> You can upload 1 images</span>
        </label>
        {vinNumberCarImagePreview && (
        <div className='vinNumberCarImage-preview'>
        <img
                       src={vinNumberCarImagePreview}
                       alt='Vin Number Car Image Preview' />
        </div>
        )}
        
        <br></br><br></br>
        
        <label className="block text-sm font-medium text-gray-700" >
        Car Images:
        <input
                     className='m-4 font-body text-gray-900'
                     type='file'
                     name='carImages'
                     multiple
                     onChange={(e) => setCarImages(e.target.files)} /> <span  className=' text-xs text-green-600 font-thin'> You can upload 5 images</span>
        </label>
        {carImagesPreview.length > 0 && (
        <div className='carImages-preview'>
        {carImagesPreview.map((imagePreview, index) => (
        <img
        src={imagePreview}
        alt="Car Image Preview"
        key={index} />
        ))}
        </div>
        )}
          </div>      
                  
                  
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-custom-red py-2 px-4 text-sm font-medium text-white shadow-sm hover:text-custom-red hover:bg-red-200/75 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Actualizando...' : 'Save'}
                  </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
        
        </div>
        
        
        </>
      )}



</>
);
}

export default UpdateProfile
