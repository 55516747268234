/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, CurrencyDollarIcon, GlobeAltIcon, LightningBoltIcon, MapIcon, ScaleIcon, ShieldCheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Affordable Prices',
    description:
      'The prices for our services are agreed upon by the supplier and may vary depending on the case and negotiations with the customer. We encourage our customers to compare prices with other providers to ensure they are getting the best value for their money.',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Quality Service',
    description:
      'We are all committed to providing excellent service and being part of the solution, not the problem. Each tester must adhere to this goal, as failure to do so may result in their removal from the system.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Case Tracking',
    description:
      'By creating a user account on our page, you will always have access to updates on your case, helping to prevent any future inconveniences. In the rare event of an issue, we will work to resolve it in an efficient and effective manner.',
    icon: MapIcon,
  },
  {
    name: 'Updated References',
    description:
      'You speak for us, and we value your experience with us. Your feedback is essential to improving our service and ensuring we meet your expectations.',
    icon: AnnotationIcon,
  },
]

export default function PageSections() {
  return (
    <div className="py-12 bg-white pt-15">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          {/* <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
          <p className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          We are committed to providing you with effective solutions.
          </p>
          <p className="mt-4 max-w-2xl text-xl font-sans text-gray-700 lg:mx-auto">
          Our greatest interest is to ensure that you obtain the service you need. We strive to facilitate this process so that both parties have better control over it.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-custom-red text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 font-body text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
