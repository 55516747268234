import React from 'react';
import MetaData from '../layout/MetaData';

import { Disclosure } from '@headlessui/react';
import { ChevronDoubleUpIcon } from '@heroicons/react/outline';

const questionsAnswers = [
    
  {
      question: 'why glasstome.com?',
      answer: 'With us in a very short time you can verify some collaborators of the service you need, we show you a summarized profile of them and a little more detailed information, as well as their reviews and images that they have uploaded, as well as some images of their services.',
  },
  {
      question: 'How can I request the service for next day?',
      answer: 'It is preferable that you contact us between 8:00 am to 12:00 pm, please verifyall your vehicle information (or have the VIN number handy) and provide the correct service address. In case you need to change the address, please call at least one hour prior to the start of your window. Our collaborator should call you at least 30 minutes before arriving. If something unforeseen happens, you will becontacted as soon as possible.',
  },
  {
      question: 'How can I request same day services?',
      answer: 'Contact one of our collaborators who have this option active, and they will guide you in the best possible way.'
  },
  {
      question: 'Do I have to register to access the services?',
      answer: 'No, you can carry out the process without registering on our website.',
  },
  {
      question: '‍Why do I have to enter information to be able to make the call?',
      answer: 'This information is used in case our collaborator cannot take the call, in this way he can communicate with you.',
  },
  {
      question: 'To make the reference, it asks me to create the user or access my user?',
      answer: 'Yes, this is due to its reliability. It is important for us to know what our collaborators think, we follow these advices and we can all improve together through communication.',
  },
  {
      question: '‍‍I dont see collaborators in my area, what can I do?',
      answer: '‍In this case you can send us an email at services@glasstome.com with the corresponding data of the vehicle and address, in a short time you should receive a call or email from us.',
  },
  {
      question: '‍I dont see where I can add my payment information?',
      answer: 'We do not request this information, the client must make the payment preferably after performing the service, in the manner agreed with the collaborator.',
  },
  {
      question: '‍A collaborator has postponed my service 3 times already?',
      answer: 'You can send us an email at services@glasstome.com with the subject name collaborator I have already been postponed 3 times. in the body of the message describe the process. once this is done we will investigate the case and then we will call you offering some possible solutions.',
  },
  {
      question: '‍I perform these services, how can I register?',
      answer: '‍You must send us an email at support@glasstome.com with the written subject: I want to add myself as a collaborator. a brief description of the company in the body of the message, and we will contact you.',
  },

]


function FrequentQuestions() {
  return (
   <>
   <MetaData title={'Frequent Questions'} />
        
      
      
        
    <div class="min-h-screen flex flex-col items-center justify-center bg-gray-100" >
    <div className="max-w-screen-lg w-full  contents px-4 pt-16">
      <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
      
        {questionsAnswers.map((questionsAnswers) => (
            <Disclosure as="div" className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-red-300 px-4 py-2 font-serif text-left text-sm  text-black hover:text-white hover:bg-red-500 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
                  <span>{questionsAnswers.question}</span>
                  <ChevronDoubleUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-custom-red`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm font-sans text-black">
                {questionsAnswers.answer}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))} 
      </div>
    </div>

    </div>

   </>
  );
}

export default FrequentQuestions;