import React from 'react';
import MetaData from '../layout/MetaData';

function TermsAndConditions() {
  return (
    <>
     
   <MetaData title={'Terms & Conditions'} />
        
      
      
        
        <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 relative content-center" >
    
    <h2  className="text-4xl font-bold text-gray-900 mb-4" ><strong>TERMS AND CONDITIONS</strong></h2>

<p className="text-lg text-gray-700 mb-6">Welcome to glasstome.com!</p>

<p  className="text-lg text-gray-700 mb-6">These terms and conditions outline the rules and regulations for the use of Glass to Me's Website, located at https://glasstome.com/.</p>

<p className="text-lg text-gray-700 mb-6">By accessing this website we assume you accept these terms and conditions. Do not continue to use glasstome.com if you do not agree to take all of the terms and conditions stated on this page.</p>

<p  className="text-lg text-gray-700 mb-6">The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>

<h3><strong>Cookies</strong></h3>

<p  className="text-lg text-gray-700 mb-6">We employ the use of cookies. By accessing glasstome.com, you agreed to use cookies in agreement with the Glass to Me's Privacy Policy. </p>

<p className="text-lg text-gray-700 mb-6">Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>

<h3 className="text-2xl font-bold text-gray-800 mb-6"><strong>License</strong></h3>

<p className="text-lg text-gray-700 mb-6">Unless otherwise stated, Glass to Me and/or its licensors own the intellectual property rights for all material on glasstome.com. All intellectual property rights are reserved. You may access this from glasstome.com for your own personal use subjected to restrictions set in these terms and conditions.</p>

<p className="text-lg text-gray-700 mb-6">You must not:</p>
<ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-3">
    <li className="bg-white shadow rounded-lg p-6">Republish material from glasstome.com</li>
    <li className="bg-white shadow rounded-lg p-6">Sell, rent or sub-license material from glasstome.com</li>
    <li className="bg-white shadow rounded-lg p-6">Reproduce, duplicate or copy material from glasstome.com</li>
    <li className="bg-white shadow rounded-lg p-6">Redistribute content from <a>glasstome.com</a></li>
    <li className="bg-white shadow rounded-lg p-6">You may use the material on our website for your own personal and non-commercial use only.</li>
    <li className="bg-white shadow rounded-lg p-6">Any other use of the material on our website, including but not limited to reproduction, modification, distribution, or republication, without our prior written consent is strictly prohibited.</li>
    <li className="bg-white shadow rounded-lg p-6">You may not use any automated system or software to extract data from our website for commercial purposes (also known as "screen scraping").</li>
    <li className="bg-white shadow rounded-lg p-6">Unauthorized use of our website or its content may give rise to a claim for damages and/or be a criminal offense.</li>
    <li className="bg-white shadow rounded-lg p-6">We reserve the right to restrict access to our website or any part of it at any time without notice.</li>
    <li className="bg-white shadow rounded-lg p-6">If you have any questions or concerns about the use of our website's content, please contact us at: <a>info@glasstome.com</a>.</li>
</ul>

<p className="text-lg text-gray-700 mb-6">This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the <a href="https://www.termsandconditionsgenerator.com/">Free Terms and Conditions Generator</a>.</p>

<p className="text-lg text-gray-700 mb-6">Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Glass to Me does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Glass to Me,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Glass to Me shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>

<p className="text-lg text-gray-700 mb-6">Glass to Me reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>

<p className="text-lg text-gray-700 mb-6">You warrant and represent that:</p>

<ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-3">
    <li className="bg-white shadow rounded-lg p-6">You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
    <li className="bg-white shadow rounded-lg p-6">The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
    <li className="bg-white shadow rounded-lg p-6">The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
    <li className="bg-white shadow rounded-lg p-6">The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
    <li className="bg-white shadow rounded-lg p-6">You agree to indemnify and hold harmless our website and its affiliates, officers, directors, employees, and agents from any and all claims and expenses, including reasonable attorneys' fees, arising from the Comments you post on our website or from any breach of these warranties and representations.</li>
    <li className="bg-white shadow rounded-lg p-6">We reserve the right to remove any Comments from our website at any time, for any reason, without notice or liability to you.</li>
    <li className="bg-white shadow rounded-lg p-6">By posting Comments on our website, you grant us a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such Comments throughout the world in any media.</li>
    <li className="bg-white shadow rounded-lg p-6">Any personal information you provide to us when posting Comments on our website will be used in accordance with our privacy policy.</li>
</ul>

<p className="text-lg text-gray-700 mb-6">You hereby grant Glass to Me a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>

<h3 className="text-2xl font-bold text-gray-800 mb-6"><strong>Hyperlinking to our Content</strong></h3>

<p className="text-lg text-gray-700 mb-6">The following organizations may link to our Website without prior written approval:</p>

<ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-3">
    <li className="bg-white shadow rounded-lg p-6">Government agencies;</li>
    <li className="bg-white shadow rounded-lg p-6">Search engines;</li>
    <li className="bg-white shadow rounded-lg p-6">News organizations;</li>
    <li className="bg-white shadow rounded-lg p-6">Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
    <li className="bg-white shadow rounded-lg p-6">System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
    <li className="bg-white shadow rounded-lg p-6">We welcome and encourage other organizations and individuals to link to our website, provided that the link does not imply endorsement or sponsorship of their website or products.</li>
    <li className="bg-white shadow rounded-lg p-6">The hyperlink should be a direct link to our website's homepage or a specific page within our website, and should not incorporate any of our website's content into their own website or service.</li>
    <li className="bg-white shadow rounded-lg p-6">We reserve the right to request the removal of any link to our website at any time, for any reason.</li>
    <li className="bg-white shadow rounded-lg p-6">If you have any questions or concerns about linking to our website, please contact us at: <a>info@glasstome.com</a></li>
</ul>

<p className="text-lg text-gray-700 mb-6">These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>

<p className="text-lg text-gray-700 mb-6">We may consider and approve other link requests from the following types of organizations:</p>

<ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-3">
    <li className="bg-white shadow rounded-lg p-6">commonly-known consumer and/or business information sources;</li>
    <li className="bg-white shadow rounded-lg p-6">dot.com community sites;</li>
    <li className="bg-white shadow rounded-lg p-6">associations or other groups representing charities;</li>
    <li className="bg-white shadow rounded-lg p-6">online directory distributors;</li>
    <li className="bg-white shadow rounded-lg p-6">internet portals;</li>
    <li className="bg-white shadow rounded-lg p-6">accounting, law and consulting firms; and</li>
    <li className="bg-white shadow rounded-lg p-6">educational institutions and trade associations.</li>
    <li className="bg-white shadow rounded-lg p-6">We reserve the right to determine whether a link request from any organization will be approved or not.</li>
    <li className="bg-white shadow rounded-lg p-6">If we do approve a link request, we may do so on the condition that the organization agrees to comply with our linking policy, and to remove the link at any time upon our request.</li>
    <li className="bg-white shadow rounded-lg p-6">We will not consider or approve link requests from organizations that we consider to be engaged in any illegal, unethical, or offensive activities, or that have a negative impact on our reputation or the reputation of our website.</li>
    <li className="bg-white shadow rounded-lg p-6">If you have any questions or concerns about link requests or our linking policy, please contact us at: <a>info@glasstome.com</a>.</li>
</ul>

<p className="text-lg text-gray-700 mb-6">We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Glass to Me; and (d) the link is in the context of general resource information.</p>

<p className="text-lg text-gray-700 mb-6">These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>

<p className="text-lg text-gray-700 mb-6">If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Glass to Me. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>

<p className="text-lg text-gray-700 mb-6">Approved organizations may hyperlink to our Website as follows:</p>

<ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-3">
    <li className="bg-white shadow rounded-lg p-6">By use of the uniform resource locator being linked to; or</li>
    <li className="bg-white shadow rounded-lg p-6">By use of our corporate name; or</li>
    <li className="bg-white shadow rounded-lg p-6">The hyperlink should not use any logos, trademarks, service marks, or other proprietary graphics or text of our website without our prior written consent.</li>
    <li className="bg-white shadow rounded-lg p-6">The hyperlink should not portray our website or its products or services in a false, misleading, derogatory, or otherwise offensive manner.</li>
    <li className="bg-white shadow rounded-lg p-6">The hyperlink should be a live and clickable hyperlink that opens in a new browser window or tab.</li>
    <li className="bg-white shadow rounded-lg p-6">We are not responsible for the content or accuracy of any website that links to our website, or for any loss or damage that may arise from the use of such website.</li>
    <li className="bg-white shadow rounded-lg p-6">By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
    <li className="bg-white shadow rounded-lg p-6">We reserve the right to modify or discontinue any aspect of our website at any time, without notice or liability to any organization that links to our website.</li>
    <li className="bg-white shadow rounded-lg p-6">If you have any questions or concerns about hyperlinking to our website, please contact us at: <a>info@glasstome.com</a>.</li>
   
</ul>

<p className="text-lg text-gray-700 mb-6">No use of Glass to Me's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

<h3 className="text-2xl font-bold text-gray-800 mb-6"><strong>iFrames</strong></h3>

<p className="text-lg text-gray-700 mb-6">Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

<h3 className="text-2xl font-bold text-gray-800 mb-6"><strong>Content Liability</strong></h3>

<p className="text-lg text-gray-700 mb-6">We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

<h3 className="text-2xl font-bold text-gray-800 mb-6"><strong>Your Privacy</strong></h3>

<p className="text-lg text-gray-700 mb-6">Please read Privacy Policy</p>

<h3 className="text-2xl font-bold text-gray-800 mb-6"><strong>Reservation of Rights</strong></h3>

<p className="text-lg text-gray-700 mb-6">We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>

<h3><strong>Removal of links from our website</strong></h3>

<p className="text-lg text-gray-700 mb-6">If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>

<p className="text-lg text-gray-700 mb-6">We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>

<h3 className="text-2xl font-bold text-gray-800 mb-6"><strong>Disclaimer</strong></h3>

<p className="text-lg text-gray-700 mb-6">To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>

<ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-3">
    <li className="bg-white shadow rounded-lg p-6">limit or exclude our or your liability for death or personal injury;</li>
    <li className="bg-white shadow rounded-lg p-6">limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
    <li className="bg-white shadow rounded-lg p-6">limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
    <li className="bg-white shadow rounded-lg p-6">exclude any of our or your liabilities that may not be excluded under applicable law.</li>
    <li className="bg-white shadow rounded-lg p-6">The information, materials, and content on our website are provided on an "as is" and "as available" basis, without any representations or warranties of any kind, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, non-infringement, or availability.</li>
    <li className="bg-white shadow rounded-lg p-6">We do not warrant that the functions or content of our website will be uninterrupted or error-free, that defects will be corrected, or that our website or the servers that make it available are free of viruses or other harmful components.</li>
    <li className="bg-white shadow rounded-lg p-6">In no event shall we be liable to you or any third party for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of or inability to use our website, even if we have been advised of the possibility of such damages.</li>
    <li className="bg-white shadow rounded-lg p-6">We do not endorse, warrant, or guarantee any third-party product or service mentioned on our website or any third-party website linked to from our website.</li>
    <li className="bg-white shadow rounded-lg p-6">Any content or materials downloaded or otherwise obtained through the use of our website is done at your own discretion and risk, and you will be solely responsible for any damage to your computer system or loss of data that results from the download of any such content or materials.</li>
    <li className="bg-white shadow rounded-lg p-6">If you have any questions or concerns about our disclaimer or liability, please contact us at: <a>info@glasstome.com</a>.</li>
</ul>

<p className="text-lg text-gray-700 mb-6">The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>

<p className="text-lg text-gray-700 mb-6">As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
    
</div>
    </>
  );
}

export default TermsAndConditions;