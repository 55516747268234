import React from 'react';
import '../Css/TailsPrueba.css'

 export default function SectionTextImg() {
    return (
       <>
       <div className="container my-24 px-6 mx-auto">
  
  {/* <!-- Section: Design Block --> */}
  <section className="mb-32 text-gray-800">

    
    <div className="container mx-auto xl:px-32 text-center lg:text-left">
      <div className="grid lg:grid-cols-2  items-center">
        <div className="mb-12 lg:mb-0">
          <div
            className="block rounded-lg shadow-lg px-6 py-12 lg:py-6 xl:py-12 md:px-12 lg:-mr-14 sectTextImg">
          <h3 className="text-2xl font-body font-bold mb-3">Why trust us?</h3>
          <h5 className="text-lg text-blue-600 font-sans font-bold mb-12 lg:mb-10 xl:mb-12">Let us answer your questions</h5>

          {/* <p className="font-bold mb-4">Anim pariatur cliche reprehenderit?</p> */}
          <p className="text-gray-700 font-sans mb-6">
          We understand the difficulties and frustrations that come with obtaining a service like this, 
          from the initial questions that arise to the leap of faith required to get started again.
          </p>

          {/* <p className="font-bold mb-4">Non cupidatat skateboard dolor brunch?</p> */}
          <p className="text-gray-700 font-sans mb-6">
          We want you to know that we are committed to making your experience as comfortable as 
          possible and providing you with high-quality service.
          </p>

          <p className=" text-gray-700 font-sans mb-4">
          We value your feedback and constantly strive to improve our services based on your comments 
          and evaluations. Your experience with our collaborators is important to us, 
          and we use your feedback to implement necessary controls to enhance our services.
          </p>
          <p className="text-gray-700 font-sans">
          We are dedicated to delivering exceptional service to you, and our goal is to provide solutions 
          and be a reliable resource for you.
          </p>
          </div>
        </div>

        <div>
          <img
            src="https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            className="w-full rounded-lg shadow-lg"
            alt=""
          />
        </div>
      </div>
    </div>
    

  </section>
 

</div>

</>
    )
 }